import React from 'react';
import {
  Container,
  Card,
  CardBody,
  Alert,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";

import FeatherIcon from "feather-icons-react";
import { httpsCallable } from "firebase/functions";
import { functions } from '../../firebase/firebase';

const DownloadResource = ({resource}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById("name-resource").value;
    const email = document.getElementById("email-resource").value;

    if (!email || !name ) {
      alert('Please fill in all fields');
      return;
    }

    // reset form
    document.getElementById('form-resource').reset();
    const sendResource = httpsCallable(functions, 'sendResource');
    sendResource({email: email, name: name, resource: resource})
    .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // const sanitizedMessage = data.text;
    });
    alert('Your resource has been sent to your email!')
  }

  return (
    <React.Fragment>
      <Card className="custom-form rounded shadow border-0">
        <CardBody>
        <h4 className="mb-4">{resource.name}</h4>
          <div id="message"></div>
          <Form
            method="post"
            onSubmit={handleSubmit}
            name="contact-form"
            id="form-resource"
          >
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    Your Name <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <i>
                      <FeatherIcon
                        icon="user"
                        className="fea icon-sm icons"
                      />
                    </i>
                  </div>
                  <Input
                    name="name"
                    id="name-resource"
                    type="text"
                    className="form-control ps-5"
                    placeholder="First Name :"
                    required
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    Your Email <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <i>
                      <FeatherIcon
                        icon="mail"
                        className="fea icon-sm icons"
                      />
                    </i>
                  </div>
                  <Input
                    name="email"
                    id="email-resource"
                    type="email"
                    className="form-control ps-5"
                    placeholder="Email :"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center">
                <div className="d-grid">
                  <input
                    type="submit"
                    id="submit"
                    name="send"
                    className="submitBnt btn btn-primary btn-block"
                    value="Download"
                  />
                </div>
                <div id="simple-msg"></div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default DownloadResource;