import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// import images
import landing from "../../../assets/images/property-management/myclassic5.png";

export default class Team extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6} className="order-1 order-md-2">
              <img
                src={landing}
                className="img-fluid rounded-md shadow-lg"
                alt=""
              />
            </Col>

            <Col
              lg={7}
              md={6}
              className="order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
            >
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">
                  Why use is <br /> communication important?
                </h4>
                <p className="text-muted mb-0 para-desc">
                  When managing multiple properties, it is importantto be able to commuincate with all tenants efficiently. For example if there is an issue in a property that needs to be fixed, it is important to be able to notify tenants.
                  {" "}
                  {/* <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "} */}
                </p>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-user" style={{color: "#f7961c"}}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                    Send mass announcements to tenants
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-file" style={{color: "#f7961c"}}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Keep a record of all communication with tenants
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
