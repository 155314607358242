import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "reactstrap";

//Import Image
import img from "../../assets/images/onepage/bg.png";
import classic from "../../assets/images/property-management/classic.png";

const TopSection = ({ onDrawerOpen, isDrawerOpen }) => {

  return (
    <React.Fragment>
      <section
        className="bg-home bg-light d-flex align-items-center"
        style={{ background: `url(${img}) top left no-repeat`, height: "auto" }}
        id="home"
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="9" className="text-center mt-0 mt-md-5 pt-0 pt-md-5">
              <div className="title-heading margin-top-100">
                <h1
                  className="heading mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Property Management{" "}<br />
                  <span style={{ color: "#f7961c" }}>Made Easy</span>
                </h1>
                <p
                  className="para-desc mx-auto text-muted"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  We provide a software tools to help property managers and landlords to gain insights into their properties and tenants.
                </p>
                <div
                  className="text-center subcribe-form mt-4 pt-2"
                  data-aos="fade-up"
                  data-aos-duration="1800"
                >
                  <Form>
                    <div className="mb-0">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="shadow bg-white rounded-pill"
                        // required
                        placeholder="Email Address"
                      />
                      <Button
                        type="submit"
                        color="primary"
                        className="btn-pills"
                        onClick={onDrawerOpen}
                        disabled={isDrawerOpen}
                      >
                        Request Demo
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              <div
                className="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
                <img src={classic} alt="" className="img-fluid rounded" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default TopSection;
