import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import AccordianCommon from "../../../components/Shared/AccordianCommon";

export default class Questions extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">Answers to your Questions</h4>
                {/* <p className="text-muted mb-0 para-desc">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p>

                <ul className="list-unstyled text-muted mt-4 mb-0">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Create your own skin to match your brand
                  </li>
                </ul> */}
              </div>
            </Col>

            <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="faq-content">
                <div className="accordion" id="accordionExampleone">
                  <AccordianCommon
                    question1="How should I structure my inventory?"
                    answer1="You can structure your inventory in whatever way makes sense for you. However, we recommend a similar structure to your property management system. For example, you can create a category for a building and then create sub categories for each unit in the building."
                    question2="How many categories can I create?"
                    answer2="You can create unlimited categories and sub categories in your inventory. This allows you to create a detailed structure that makes sense for your property."
                    question3="How do I add an inventory to a lease?"
                    answer3="To add an inventory to a lease, you first need to assign the category to a unit. Whenever you create a lease for that unit, the inventory will automatically be added to the lease."
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="row mt-5 pt-md-4 justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                {/* <p className="text-muted para-desc mx-auto">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p> */}
                <Link
                  to="/contact"
                  className="btn btn-primary btn-pills mt-4"
                >
                  <i className="uil uil-phone"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
