import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
// import img from "../../assets/images/saas/classic01.png";
import img from "../../assets/images/saas/myclassic6.png";

const Section = ({ onDrawerOpen, isDrawerOpen }) => {

  return (
    <React.Fragment>
      <section
        className="bg-half-170 d-table w-100 overflow-hidden"
        id="home"
      >
        <Container>
          <Row className="align-items-center pt-5">
            <Col lg={7} md={6}>
              <div className="title-heading" >
                <h1 className="heading mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                  AI Powered Property <br /> Management Solution
                </h1>
                <p className="para-desc text-muted" style={{ display: 'flex', justifyContent: 'center' }}>
                  Zenstrin is a property management solution for property managers and landlords, we leverage AI and software to simplify and automate the process of onboarding tenants, collecting rent and tenant management.
                </p>
                <div className="mt-4" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Link to="//app.zenstrin.com/signup" target="_blank" rel="noopener noreferrer" className="btn btn-pills btn-primary mt-2 me-2" style={{ display: 'flex', }}>
                    <div>
                      <strong className="d-block " style={{ fontWeight: 'medium', fontSize: 17 }}>Start Free Trial</strong>
                    </div>
                  </Link>
                  <Link to="#" onClick={onDrawerOpen} disabled={isDrawerOpen} className="btn btn-pills btn-outline-primary mt-2 ms-1" style={{ display: 'flex', }}>
                    <div>
                      <strong className="d-block " style={{ fontWeight: 'medium', fontSize: 17 }}>
                        Request Demo
                        <span className="badge rounded-pill bg-danger ms-2">
                          v2
                        </span></strong>
                    </div>
                  </Link>
                </div>
                {/* <div className="mt-4 pt-2">
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={onDrawerOpen}
                    disabled={isDrawerOpen}
                  >
                    Request Demo
                    <span className="badge rounded-pill bg-danger ms-2">
                      v2
                    </span>
                  </Link>
                </div> */}
              </div>
            </Col>

            <Col lg={5} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="classic-saas-image position-relative">
                <div className="bg-saas-shape position-relative">
                  <img src={img} className="mx-auto d-block" alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Section;
