import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// import images
import bg from "../../../assets/images/bg.png";

const Section = ({ onDrawerOpen, isDrawerOpen }) => {
    return (
      <React.Fragment>
        <section
          className="bg-half-260 d-table w-100"
          style={{
            background: `url(${bg}) center center`,
            // backgroundColor: "#e07e02"
            backgroundColor: "#fcba68"
          }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col lg={12} className="text-center">
                <div className="title-heading">
                  <h1 className="heading text-white title-dark mb-4">
                    Lease Management <br /> Rental Property
                  </h1>
                  <p className="para-desc mx-auto text-white">
                    Store all your documents and communications in one place. <br /> Manage your leases and tenants with ease.
                  </p>
                  <div className="mt-4 pt-2">
                    <Link to="//app.zenstrin.com/signup" target="_blank" rel="noopener noreferrer" className="btn btn-light btn-primary btn-pills me-2">
                      <i className="uil align-middle"></i> Start Free Trial
                    </Link>
                    <Link to="#" onClick={onDrawerOpen} disabled={isDrawerOpen} className="btn btn-light btn-pills btn-outline-primary ms-1">
                      <i className="uil align-middle"></i> Request Demo
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
}

export default Section;