import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// import images
import landing from "../../../assets/images/property-management/inventory-management.png";

export default class Team extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6} className="order-1 order-md-2">
              <img
                src={landing}
                className="img-fluid rounded-md shadow-lg"
                alt=""
              />
            </Col>

            <Col
              lg={7}
              md={6}
              className="order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
            >
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">
                  Why is inventory Management<br /> important for your property?
                </h4>
                <p className="text-muted mb-0 para-desc">
                  Inventory management is critical in property management because it helps you keep track of the items in your property. It helps you know what you have, where it is, and how much of it you have. It is also important for tracking the condition of items in your property to ensure they are in good condition for your tenants.
                  {" "}
                  {/* <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "} */}
                </p>

                <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-archive" style={{color: "#f7961c"}}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Create your inventory structure and add items to it
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-home" style={{color: "#f7961c"}}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Assign inventory items to properties 
                    </Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-camera" style={{color: "#f7961c"}}></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">
                      Take pictures of items and track their condition
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
