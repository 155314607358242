import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Images
import bgimg from "../../../../assets/images/1.jpg";
import Newsletter from "../../../../components/Layout/Newsletter";

export default class RoleOfAStudentTenantGuarantorInRentalAgreements extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu").classList.add("nav-light");
    })
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".settingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".settingbtn").classList.remove("btn-soft-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      // document.querySelector(".settingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" style={{ background: `url(${bgimg}) center center` }}>
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h3 className="title text-white title-dark mb-0"> Student Tenant Guarantor <br />in Rental Agreements </h3>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="#">Blog</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">Understanding the Vital Role of a Student Tenant Guarantor in Rental Agreements</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon social mb-0">
                      <li className="mb-2 h6">Share</li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.instagram.com/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.linkedin.com/company/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                  <div className="col-md-10">

                    <ul className="list-unstyled d-flex justify-content-between mt-4">
                      <li className="list-inline-item user me-2">
                        <Link to="#" className="text-muted">
                          <i className="uil uil-user text-dark"></i> Zenstrin
                        </Link>
                      </li>
                      <li className="list-inline-item date text-muted">
                        <i className="uil uil-calendar-alt text-dark"></i>{" "}
                        21st March, 2024
                      </li>
                    </ul>

                    <img
                      src={"https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Funderstanding-the-vital-role-of-a-student-tenant-guarantor-in-rental-agreements.jpg?alt=media&token=2f207fc1-ff94-40c1-afec-c71fe10b537f"}
                      className="img-fluid rounded-md shadow"
                      alt=""
                    />

                    <h5 className="mt-4">
                      Understanding the Vital Role of a Student Tenant Guarantor in Rental Agreements
                    </h5>

                    <p className="text-muted">
                      In the realm of student housing, the role of a guarantor plays a pivotal role in securing rental agreements. As students venture into independent living away from home, landlords often require a guarantor to mitigate financial risks associated with renting to individuals with limited credit history or income. This article delves into the significance of a student tenant guarantor, outlining their responsibilities, implications, and the importance of their role in rental agreements.
                    </p>

                    <h5 className="mt-4">
                      Understanding the Student Tenant Guarantor:
                    </h5>

                    <p className="text-muted">
                      A student tenant guarantor serves as a co-signer on a rental agreement, providing assurance to landlords that rent payments will be made in full and on time. Typically, the guarantor is a parent or guardian who agrees to assume financial responsibility for the tenant's obligations if they fail to meet them. While the primary tenant may be responsible for rent and damages, the guarantor acts as a safety net, offering landlords additional security in case of default.
                    </p>

                    <h5 className="mt-4">
                      Responsibilities of a Guarantor:
                    </h5>

                    <p className="text-muted">
                      1. Financial Liability: The primary responsibility of a guarantor is to guarantee the financial obligations of the tenant, including rent payments, property damages, and any additional costs outlined in the lease agreement. In the event of non-payment by the tenant, the guarantor may be legally obligated to cover these expenses.
                    </p>

                    <p className="text-muted">
                      2. Creditworthiness: Guarantors are often required to demonstrate sufficient creditworthiness and financial stability to reassure landlords of their ability to fulfill their obligations. This may involve undergoing a credit check and providing proof of income or assets.
                    </p>

                    <p className="text-muted">
                      3. Legal Obligations: By signing the rental agreement as a guarantor, individuals agree to be bound by the terms and conditions outlined in the contract. This includes adhering to lease terms, complying with property rules, and addressing any breaches of contract that may arise.
                    </p>

                    <p className="text-muted">
                      Communication: Guarantors must maintain open lines of communication with both the tenant and the landlord throughout the tenancy. This ensures prompt resolution of any issues or concerns that may arise, such as missed rent payments or property damage.
                    </p>

                    <h5>
                      Importance of a Guarantor in Student Housing:
                    </h5>

                    <p className="text-muted">
                      1.Limited Financial Resources: Many students have limited income and credit history, making them higher-risk tenants for landlords. A guarantor provides landlords with peace of mind by offering financial security and minimizing the risk of default.
                    </p>

                    <p className="text-muted">
                      2. Rental Eligibility: Having a guarantor increases a student's eligibility for rental properties that may have strict income or credit requirements. This expands housing options and allows students to secure accommodation that meets their needs and preferences.
                    </p>

                    <p className="text-muted">
                      3. Landlord Protection: Guarantors provide landlords with an added layer of protection against potential financial losses associated with tenant defaults. This encourages landlords to rent to students, facilitating access to housing in competitive rental markets.
                    </p>

                    <p>
                      4. Legal Enforcement: In the event of tenant non-payment or breach of lease terms, landlords have recourse to pursue legal action against the guarantor to recover any outstanding debts or damages. This incentivizes guarantors to fulfill their obligations and encourages tenants to uphold their lease agreements.
                    </p>

                    <h5 className="mt-4">
                      Conclusion
                    </h5>

                    <p className="text-muted">
                      In the realm of student housing, the role of a guarantor is indispensable in facilitating rental agreements and providing landlords with financial security. By assuming responsibility for the tenant's obligations, guarantors play a vital role in mitigating risks associated with renting to students while expanding housing opportunities for this demographic. Understanding the responsibilities and implications of serving as a guarantor is essential for both landlords and tenants, ensuring transparent and mutually beneficial rental agreements in student housing markets.
                    </p>

                    <Newsletter />
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
