import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Images
import bgimg from "../../../../assets/images/1.jpg";
import Newsletter from "../../../../components/Layout/Newsletter";

export default class WhatLandlordsExpectFromLettingAgencies extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu").classList.add("nav-light");
    })
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".settingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".settingbtn").classList.remove("btn-soft-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      // document.querySelector(".settingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" style={{ background: `url(${bgimg}) center center` }}>
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h3 className="title text-white title-dark mb-0"> Understanding What Landlords  <br />Expect from Letting Agencies </h3>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="#">Blog</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">Meeting Expectations: Understanding What Landlords Expect from Letting Agencies?</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon social mb-0">
                      <li className="mb-2 h6">Share</li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.instagram.com/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.linkedin.com/company/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                  <div className="col-md-10">

                    <ul className="list-unstyled d-flex justify-content-between mt-4">
                      <li className="list-inline-item user me-2">
                        <Link to="#" className="text-muted">
                          <i className="uil uil-user text-dark"></i> Zenstrin
                        </Link>
                      </li>
                      <li className="list-inline-item date text-muted">
                        <i className="uil uil-calendar-alt text-dark"></i>{" "}
                        21st March, 2024
                      </li>
                    </ul>

                    <img
                      src={"https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fmeeting-expectations-understanding-what-landlords-expect-from-letting-agencies.jpg?alt=media&token=f2da004c-2914-4354-9af2-33a5c157fcb7"}
                      className="img-fluid rounded-md shadow"
                      alt=""
                    />

                    <h5 className="mt-4">
                      Meeting Expectations: Understanding What Landlords Expect from Letting Agencies
                    </h5>

                    <p className="text-muted">
                      Letting agencies play a pivotal role in the property management ecosystem, acting as intermediaries between landlords and tenants. For landlords entrusting their properties to these agencies, there are certain expectations regarding the services they provide. This article explores the key expectations landlords have from letting agencies and how agencies can meet and exceed these expectations to foster successful partnerships.
                    </p>

                    <h5 className="mt-4">
                      Clear Communication Channels:
                    </h5>

                    <p className="text-muted">
                      One of the fundamental expectations landlords have from letting agencies is clear and transparent communication channels. Landlords appreciate timely updates on property management matters, including tenant inquiries, maintenance requests, and financial reports. Effective communication instills confidence in landlords, demonstrating that their properties are in capable hands. Letting agencies should prioritize regular updates via email, phone calls, or online portals to keep landlords informed and involved in property management decisions.
                    </p>

                    <h5 className="mt-4">
                      Tenant Screening and Placement:
                    </h5>

                    <p className="text-muted">
                      Landlords expect letting agencies to conduct thorough tenant screening processes to secure reliable and responsible tenants for their properties. This involves background checks, credit assessments, and rental history verifications to mitigate the risk of potential rent defaults or property damage. Additionally, landlords rely on letting agencies to facilitate the tenant placement process efficiently, ensuring vacancies are filled promptly with suitable tenants who meet the property's criteria.
                    </p>

                    <h5 className="mt-4">
                      Property Maintenance and Repairs:
                    </h5>

                    <p className="text-muted">
                      Maintaining the condition of rental properties is crucial for landlords to protect their investments and ensure tenant satisfaction. Letting agencies are expected to oversee regular maintenance tasks and address repair issues promptly to uphold property standards. Landlords appreciate proactive property management practices, such as conducting routine inspections and coordinating timely repairs, to prevent minor issues from escalating into costly problems.
                    </p>

                    <h5 className="mt-4">
                      Financial Management and Reporting:
                    </h5>

                    <p className="text-muted">
                      Effective financial management is another area where landlords expect letting agencies to excel. This includes rent collection, expense tracking, and providing detailed financial reports to landlords. Transparent accounting practices instill trust and accountability, allowing landlords to monitor rental income and expenses accurately. Letting agencies should utilize property management software to streamline financial processes and generate comprehensive reports that offer insights into the property's financial performance.
                    </p>

                    <h5 className="mt-4">
                      Legal Compliance and Regulatory Knowledge:
                    </h5>

                    <p className="text-muted">
                      Landlords rely on letting agencies to navigate complex legal requirements and ensure compliance with relevant regulations governing the rental market. From tenancy agreements to eviction procedures, letting agencies should demonstrate a thorough understanding of landlord-tenant laws and stay updated on any legislative changes. Landlords expect agencies to act in their best interests and protect them from potential legal liabilities by adhering to all legal obligations and obligations.
                    </p>

                    <h5 className="mt-4">
                      Conclusion
                    </h5>

                    <p className="text-muted">
                      In conclusion, meeting landlords' expectations is essential for letting agencies to build trust, foster long-term partnerships, and thrive in the competitive property management industry. By prioritizing clear communication, thorough tenant screening, proactive property maintenance, transparent financial management, and compliance with legal regulations, letting agencies can exceed landlords' expectations and deliver exceptional service. Ultimately, successful collaboration between landlords and letting agencies hinges on mutual trust, effective communication, and a shared commitment to property management excellence.
                    </p>

                    <Newsletter />
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
