import React, { useState, useEffect } from "react";
import ReactDrawer from 'react-drawer';

// import component
import Section from "./Section";
import Features from "./Features";
// import About from "./About";
import Team from "./Team";
// import Partners from "./Partners";
// import Client from "./Client";
import Questions from "./Questions";

import RightSidebar from "../../../components/Layout/RightSidebar";

const FileStorage = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
    });
    window.addEventListener("scroll", scrollNavigation, true);
  })

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".settingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document.querySelector(".settingbtn")?.classList.remove("btn-soft-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.querySelector(".settingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };
  return (
    <React.Fragment>
      <Section onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} />
      <section className="section">
        <Features />
        {/* <Team /> */}
      </section>
      <section className="section bg-light">
        {/* <Partners /> */}
        {/* <Client /> */}
        <Questions />
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-footer">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <ReactDrawer
        open={isDrawerOpen}
        position={"right"}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  );
}

export default FileStorage;