import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import AccordianCommon from "../../../components/Shared/AccordianCommon";

export default class Questions extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">Answers to your Questions</h4>
                {/* <p className="text-muted mb-0 para-desc">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p>

                <ul className="list-unstyled text-muted mt-4 mb-0">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Create your own skin to match your brand
                  </li>
                </ul> */}
              </div>
            </Col>

            <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="faq-content">
                <div className="accordion" id="accordionExampleone">
                  <AccordianCommon
                    question1="Can I send rent reminders to tenants ?"
                    answer1="Yes, our software automatically sends rent reminders to tenants a day before the rent is due."
                    question2="Can I create reports for my property ?"
                    answer2="Yes, you can create reports for your property and schedule recurring reports to be sent to your email."
                    question3="How can I manage the inventory of my property ?"
                    answer3="At the start of every lease, you can add images of the items in your property and their conditions. At the end of the lease, your tenants can upload images of the items and their conditions."
                    question4="How can I track the payments of my tenants ?"
                    answer4="You can connect your bank account to our software. When tenants pay their rent we will track the payment and assign it to the correct lease and property."
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="row mt-5 pt-md-4 justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                {/* <p className="text-muted para-desc mx-auto">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p> */}
                <Link
                  to="/contact"
                  className="btn btn-primary btn-pills mt-4"
                >
                  <i className="uil uil-phone"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
