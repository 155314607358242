import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Trial = ({ onDrawerOpen, isDrawerOpen }) => {
  return (
    <React.Fragment>
      <Container className="mt-100 mt-60">
        <div className="rounded bg-warning bg-gradient p-lg-5 p-4">
          <Row className="align-items-end">
            <Col md={7}>
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-3 text-white title-dark">
                  Get started
                </h4>
                <p className="text-white-50 mb-0">
                  Start working with Zenstrin, request a demo and check if we are the right fit.
                </p>
              </div>
            </Col>

            <Col md={5} className="mt-4 mt-sm-0" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="text-md-end text-center me-2">
                <Link to="//app.zenstrin.com/signup" target="_blank" rel="noopener noreferrer" className="btn btn-pills btn-light">
                  <strong className="d-block " style={{ fontWeight: 'medium', fontSize: 17 }}>
                    Start Free Trial
                  </strong>
                </Link>
              </div>
              <div className="text-md-end text-center">
                <Link to="#" className="btn btn-pills btn-light" onClick={onDrawerOpen}
                  disabled={isDrawerOpen}>
                  <strong className="d-block " style={{ fontWeight: 'medium', fontSize: 17 }}>
                    Request Demo
                  </strong>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Trial;
