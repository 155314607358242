import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import AccordianCommon from "../../../components/Shared/AccordianCommon";

export default class Questions extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">Answers to your Questions</h4>
                {/* <p className="text-muted mb-0 para-desc">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p>

                <ul className="list-unstyled text-muted mt-4 mb-0">
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mb-1">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Create your own skin to match your brand
                  </li>
                </ul> */}
              </div>
            </Col>

            <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="faq-content">
                <div className="accordion" id="accordionExampleone">
                  <AccordianCommon
                    question1="Can I customize what information I ask for ?"
                    answer1="Yes, you can customize the questions you ask in the application form. You can also collect any documents you require from the applicant such as ID, proof of income etc."
                    question2="How will I know if the applicant has started filling out the application form ?"
                    answer2="You can track the progress of your applicants in your Zenstrin account. You can aslo see what stage of the application process they are at. When the appllication has been completed by all applicants, you will receive a notification."
                    question3="Do tenants have to finish the application in one sitting ?"
                    answer3="No, tenants can save their progress and come back to the application at a later time."
                    question4="Can tenants add other applicants to the application ?"
                    answer4="Yes, tenants can add other applicants to the application form. Each applicant will receive a link to fill out their own application form."
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="row mt-5 pt-md-4 justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                {/* <p className="text-muted para-desc mx-auto">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p> */}
                <Link
                  to="/contact"
                  className="btn btn-primary btn-pills mt-4"
                >
                  <i className="uil uil-phone"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
