import React, { Component } from "react";
import {
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import rentalApplications from "../../assets/images/property-management/myclassic6.png";
import automations from "../../assets/images/property-management/myclassic3.png";
import tasks from "../../assets/images/property-management/myclassic2.png";
import overview from "../../assets/images/property-management/classic.png";

export default class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4">
                  End-to-end {" "}
                  <span className="" style={{ color: "#f7961c" }}>solution</span>
                </h4>
                {/* <p className="text-muted para-desc mb-0 mx-auto">
                  Start working with{" "}
                  <span className=" fw-bold" style={{ color: "#f7961c" }}>
                    Zenstrin
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p> */}
              </div>
            </Col>{" "}
          </Row>
          <Row className="align-items-center">
            <Col md={5} className="mt-4 pt-2">
              <ul
                className="nav nav-pills nav-justified flex-column mb-0"
                id="pills-tab"
                role="tablist"
              >
                <NavItem className="bg-light rounded-md">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: this.state.activeTab === "1" },
                      "rounded-md"
                    )}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    <div className="p-3 text-start">
                      <h5 className="title">Rental Applications & Document signing</h5>
                      <p className="text-muted tab-para mb-0">
                        Gather information from prospects and screen them.
                        Send lease agreements to tenants and get them signed electronically.
                      </p>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="bg-light rounded-md mt-4">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: this.state.activeTab === "2" },
                      "rounded-md"
                    )}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    <div className="p-3 text-start">
                      <h5 className="title">Rent Collection & Analytics</h5>
                      <p className="text-muted tab-para mb-0">
                        Sync your bank account and get your rental transactions synced automatically. Track inflows and outflows for each property, lease and tenant.
                      </p>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="bg-light rounded-md mt-4">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: this.state.activeTab === "3" },
                      "rounded-md"
                    )}
                    onClick={() => {
                      this.toggleTab("3");
                    }}
                  >
                    <div className="p-3 text-start">
                      <h5 className="title">Issue Management and Service Partners</h5>
                      <p className="text-muted tab-para mb-0">
                        Ensure tenant satisfaction by managing maintenance requests and make sure not to miss a request.
                        Schedule jobs for service partners or allow our AI to schedule jobs.
                      </p>
                    </div>
                  </NavLink>
                </NavItem>

                <NavItem className="bg-light rounded-md mt-4">
                  <NavLink
                    to="#"
                    className={classnames(
                      { active: this.state.activeTab === "4" },
                      "rounded-md"
                    )}
                    onClick={() => {
                      this.toggleTab("4");
                    }}
                  >
                    <div className="p-3 text-start">
                      <h5 className="title">Automations</h5>
                      <p className="text-muted tab-para mb-0">
                        We automatically send lease reminders and generate reports on your properties.
                        Schedule automations to run at any time.
                      </p>
                    </div>
                  </NavLink>
                </NavItem>
              </ul>
            </Col>
            <Col md={7} xs={12} className="mt-4 pt-2">
              <TabContent className="ms-lg-4" activeTab={this.state.activeTab}>
                <TabPane className="show fade" tabId="1">
                  <img
                    src={rentalApplications}
                    className="img-fluid mx-auto rounded-md shadow-lg d-block"
                    alt=""
                  />
                </TabPane>

                <TabPane className="fade show" tabId="2">
                  <img
                    src={overview}
                    className="img-fluid mx-auto rounded-md shadow-lg d-block"
                    alt=""
                  />
                </TabPane>

                <TabPane className="show fade" tabId="3">
                  <img
                    src={tasks}
                    className="img-fluid mx-auto rounded-md shadow-lg d-block"
                    alt=""
                  />
                </TabPane>

                <TabPane className="tab-pane fade show" tabId="4">
                  <img
                    src={automations}
                    className="img-fluid mx-auto rounded-md shadow-lg d-block"
                    alt=""
                  />
                </TabPane>
              </TabContent>
            </Col>{" "}
          </Row>{" "}
        </Container>
      </React.Fragment>
    );
  }
}
