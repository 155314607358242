import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Images
import bgimg from "../../../../assets/images/1.jpg";
import Newsletter from "../../../../components/Layout/Newsletter";

export default class HowYourLettingAgencyCanMaketheMostofHybridWorking extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu").classList.add("nav-light");
    })
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".settingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".settingbtn").classList.remove("btn-soft-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      // document.querySelector(".settingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" style={{ background: `url(${bgimg}) center center` }}>
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h3 className="title text-white title-dark mb-0">
                    How Your Letting Agency Can Make<br />the Most of Hybrid Working </h3>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="#">Blog</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">How Your Letting Agency Can Make the Most of Hybrid Working</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon social mb-0">
                      <li className="mb-2 h6">Share</li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.instagram.com/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.linkedin.com/company/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                  <div className="col-md-10">

                    <ul className="list-unstyled d-flex justify-content-between mt-4">
                      <li className="list-inline-item user me-2">
                        <Link to="#" className="text-muted">
                          <i className="uil uil-user text-dark"></i> Zenstrin
                        </Link>
                      </li>
                      <li className="list-inline-item date text-muted">
                        <i className="uil uil-calendar-alt text-dark"></i>{" "}
                        20th March, 2024
                      </li>
                    </ul>

                    <img
                      src={"https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fhow-your-letting-agency-can-make-the-most-of-hybrid-working.jpg?alt=media&token=07479284-d8dc-4ee9-9d1b-4db903d066e7"}
                      className="img-fluid rounded-md shadow"
                      alt=""
                    />

                    <h5 className="mt-4">
                      How Your Letting Agency Can Make the Most of Hybrid Working
                    </h5>

                    <p className="text-muted">
                      The landscape of work has shifted dramatically in recent years, and the COVID-19 pandemic accelerated the adoption of flexible work arrangements. As a result, many businesses, including letting agencies, have embraced hybrid working models that combine remote and in-office work. In this article, we'll explore how your letting agency can make the most of hybrid working to enhance productivity, collaboration, and employee satisfaction.
                    </p>

                    <h5 className="mt-4">Embrace Flexibility</h5>

                    <p className="text-muted">
                      Hybrid working offers employees the flexibility to choose where they work, whether it's from home, a co-working space, or the office. By embracing this flexibility, letting agencies can accommodate diverse work preferences and lifestyles among their employees. This can lead to higher job satisfaction, improved work-life balance, and increased productivity.

                      To make the most of flexibility in hybrid working, letting agencies should establish clear guidelines and expectations for remote work. This includes defining core hours when employees are expected to be available for meetings and collaboration, as well as setting up systems for communication and task management to ensure smooth workflow regardless of location.
                    </p>

                    <h5 className="mt-4">Invest in Technology</h5>

                    <p className="text-muted">
                      Technology plays a crucial role in enabling effective hybrid working. Letting agencies should invest in tools and software that facilitate seamless communication, collaboration, and access to information from anywhere. This may include video conferencing platforms, project management software, document sharing tools, and cloud-based storage solutions.

                      By providing employees with the right technology and training, letting agencies can ensure that remote work is as productive and efficient as in-office work. Additionally, technology can help bridge the gap between remote and in-office workers, fostering a sense of connection and belonging among team members.
                    </p>

                    <h5 className="mt-4">Foster Communication and Collaboration</h5>

                    <p className="text-muted">
                      Effective communication and collaboration are essential for the success of hybrid working. Letting agencies should establish regular check-ins, team meetings, and virtual collaboration sessions to keep employees connected and informed. This can help prevent silos and ensure that everyone is aligned on goals, priorities, and deadlines.

                      In addition to scheduled meetings, letting agencies should encourage informal communication and collaboration through channels such as instant messaging platforms or virtual water cooler chats. Building a strong sense of community and camaraderie among employees, regardless of their location, can help maintain morale and motivation.
                    </p>

                    <h5 className="mt-4">Prioritize Well-Being</h5>

                    <p className="text-muted">
                      Hybrid working offers opportunities for letting agencies to prioritize employee well-being and work-life balance. By allowing employees to work remotely part of the time, letting agencies can reduce commuting stress, increase flexibility for personal commitments, and promote overall health and well-being.

                      To support employee well-being in a hybrid working environment, letting agencies should encourage regular breaks, provide resources for mental health support, and promote a culture of work-life balance. This may include offering flexible working hours, wellness programs, or access to counseling services.
                    </p>

                    <h5 className="mt-4">Lead by Example</h5>

                    <p className="text-muted">
                      Finally, leadership plays a crucial role in making the most of hybrid working. Managers and supervisors should lead by example by embracing hybrid working themselves and demonstrating trust and confidence in their teams. This includes setting clear expectations, providing support and guidance, and recognizing and celebrating achievements.

                      Effective leadership in a hybrid working environment involves communication, empathy, and adaptability. Managers should be accessible and approachable, actively seeking feedback and input from their team members, and demonstrating a commitment to continuous improvement and learning.
                    </p>

                    <h5 className="mt-4">Conclusion</h5>

                    <p className="text-muted">
                      In conclusion, hybrid working offers letting agencies an opportunity to embrace flexibility, invest in technology, foster communication and collaboration, prioritize employee well-being, and lead by example. By embracing these principles, letting agencies can create a thriving and productive work environment that benefits both employees and the business as a whole.
                    </p>

                    <Newsletter />
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
