import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';

//Import Icons
import FeatherIcon from "feather-icons-react";

//Images
// import client01 from "../../assets/images/client/01.jpg";
// import client02 from "../../assets/images/client/02.jpg";
// import client03 from "../../assets/images/client/03.jpg";
// import client04 from "../../assets/images/client/04.jpg";
// import client05 from "../../assets/images/client/05.jpg";
// import client06 from "../../assets/images/client/06.jpg";

const propertyManagementFeatures = [
  {
    icon: "bar-chart",
    title: "Bookkeeping & Analytics",
    desc:
      "Track the performance of your properties over time. Get insights on your portfolio.",
  },
  {
    icon: "dollar-sign",
    title: "Bank Account Sync",
    desc:
      "Sync your bank account and get your rental transactions synced automatically.",
  },
  {
    icon: "tool",
    title: "Tenant issues",
    desc:
      "Manage tenant issues and track the status of each issue. Chat with your tenants. Automate issues with our AI.",
  },
  {
    icon: "globe",
    title: "Property landing pages",
    desc:
      "Create stunning landing pages for each of your properties.",
  },
  {
    icon: "repeat",
    title: "Automations",
    desc:
      "Automate lease reminders and property reports. Schedule automations to run at any time.",
  },
  {
    icon: "briefcase",
    title: "Rental Applications",
    desc:
      "Send rental applications to your tenants and gather all the information you need to make a decision. Use our AI to screen tenants.",
  },
  {
    icon: "file-text",
    title: "Document signing",
    desc:
      "We send lease agreements to your tenants and get them signed online. Store all your documents in one place.",
  },
];

const propertyDevelopmentFeatures = [
  {
    icon: "briefcase",
    title: "Project Management",
    desc:
      "Compare your project budget with actual costs. Track your project progress and milestones. Get insights on your project.",
  },
  {
    icon: "file-text",
    title: "Vendors and Contracts Management",
    desc:
      "Manage your contracts, assign contracts to vendors. Track payables and receivables. Get insights on your contracts.",
  },
  {
    icon: "tool",
    title: "Task Management",
    desc:
      "Assign tasks to your team members. Track task progress. Get insights on your tasks.",
  },
  {
    icon: "repeat",
    title: "Automations",
    desc:
      "Automate project, vendor or property reports. Schedule automations to run at any time.",
  },
  {
    icon: "archive",
    title: "Inventory Management",
    desc:
      "Track your inventory in any property or storage location. Create sub-locations to store items.",
  },
];

const agentFeatures = [
  {
    icon: "shopping-bag",
    title: "Marketplace",
    desc:
      "Browse through marketplace Set your price and add the property to your store. Start selling with ease and leave the rest to us.",
  },
  {
    icon: "bar-chart",
    title: "Analytics",
    desc:
      "View recent transactions and reservations on your property.",
  },
  {
    icon: "globe",
    title: "Property website",
    desc:
      "Create a stunning website for your properties. Promote your website and get more bookings.",
  },
];

const tenantFeatures = [
  {
    icon: "home",
    title: "Find a property",
    desc:
      "Find a property that suits your needs. Filter properties based on your preferences.",
  },
  {
    icon: "file-text",
    title: "Manage your lease",
    desc:
      "Access your lease details. Access your documents such as lease agreement.",
  },
  {
    icon: "bar-chart",
    title: "Rent payment",
    desc:
      "Pay your rent online. View your payment history.",
  },
  {
    icon: "tool",
    title: "Raise maintenance requests",
    desc:
      "Raise maintenance requests. Track the status of your requests. Chat with your property manager.",
  },
  {
    icon: "message-circle",
    title: "AI Chatbot",
    desc:
      "Chat with our AI to get answers to your questions.",
  },
];

class FeatureDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: 4,
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
    };
    this.updateWindowSize.bind(this);
  }

  updateWindowSize = () => {
    if (window.outerWidth >= 1230) {
      this.setState({ itemCount: 3, cols: 4 });
    } else if (window.outerWidth >= 970 && window.outerWidth < 1230) {
      this.setState({ itemCount: 2, cols: 6 });
    } else if (window.outerWidth <= 970) {
      this.setState({ itemCount: 1, cols: 12 });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowSize);
    this.updateWindowSize();
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  }

  render() {
    return (
      <React.Fragment>
        <Container className="pb-5 mb-md-5 mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="saas-feature-shape-left position-relative">
                <img
                  src={this.props.image1}
                  className="img-fluid mx-auto d-block rounded shadow"
                  alt=""
                />
              </div>
            </Col>

            <Col lg={5} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
              <div className="section-title ms-lg-4">
                <h1 className="title mb-3">Property Management Solution</h1>
                <p className="para-desc text-muted">
                  We equip you with software tools that help you manage your properties and tenants with ease.
                </p>

                <Row>
                  {propertyManagementFeatures.map((facility, key) => (
                    <Col xs="12" key={key}>
                      <div className="d-flex align-items-center pt-4">
                        <h2>
                          <i>
                            <FeatherIcon
                              icon={facility.icon}
                              className="fea icon-m-md "
                              style={{ color: "#f7961c" }}
                            />
                          </i>
                        </h2>
                        <div className="ms-3">
                          <h5> {facility.title} </h5>
                          <p className="text-muted mb-0">{facility.desc}</p>
                        </div>
                      </div>
                    </Col>
                  ))}

                  <Col xs="12" className="pt-4">
                    <Link to="/features" className="btn btn-pills btn-outline-primary"
                    // onClick={this.props.onDrawerOpen} disabled={this.props.isDrawerOpen}
                    >
                      Learn More <i className="uil uil-angle-right-b"></i>
                    </Link>
                  </Col>
                </Row>
                {/* <div
                  id="customer-testi"
                  className="owl-carousel owl-theme owl-loaded owl-drag"
                >
                  <div className="owl-stage-outer">
                    <div className="owl-stage">
                      <Row>
                        {this.state.step1 === true ? (
                          <div className="customer-testi">
                            <img
                              src={client01}
                              className="img-fluid avatar avatar-small rounded-circle shadow"
                              alt="Landrick"
                            />
                            <p className="text-muted mt-4">
                              " It seems that only fragments of the original
                              text remain in the Lorem Ipsum texts used today. "
                            </p>
                            <h6 className="text-primary">- Thomas Israel</h6>
                          </div>
                        ) : null}
                        {this.state.step2 === true ? (
                          <div className="customer-testi">
                            <img
                              src={client02}
                              className="img-fluid avatar avatar-small rounded-circle shadow"
                              alt="Landrick"
                            />
                            <p className="text-muted mt-4">
                              " The most well-known dummy text is the 'Lorem Ipsum',
                              which is said to have originated in the 16th century. "
                            </p>
                            <h6 className="text-primary">- Carl Oliver</h6>
                          </div>
                        ) : null}
                        {this.state.step3 === true ? (
                          <div className="customer-testi">
                            <img
                              src={client03}
                              className="img-fluid avatar avatar-small rounded-circle shadow"
                              alt="Landrick"
                            />
                            <p className="text-muted mt-4">
                              " One disadvantage of Lorum Ipsum is that in Latin certain
                              letters appear more frequently than others. "
                            </p>
                            <h6 className="text-primary">- Barbara McIntosh</h6>
                          </div>
                        ) : null}
                        {this.state.step4 === true ? (
                          <div className="customer-testi">
                            <img
                              src={client04}
                              className="img-fluid avatar avatar-small rounded-circle shadow"
                              alt="Landrick"
                            />
                            <p className="text-muted mt-4">
                              " Thus, Lorem Ipsum has only limited suitability
                              as a visual filler for German texts. "
                            </p>
                            <h6 className="text-primary">- Thomas Israel</h6>
                          </div>
                        ) : null}
                        {this.state.step5 === true ? (
                          <div className="customer-testi">
                            <img
                              src={client05}
                              className="img-fluid avatar avatar-small rounded-circle shadow"
                              alt="Landrick"
                            />
                            <p className="text-muted mt-4">
                              " There is now an abundance of readable dummy texts.
                              These are usually used when a text is required. "
                            </p>
                            <h6 className="text-primary">- Jill Webb</h6>
                          </div>
                        ) : null}
                        {this.state.step6 === true ? (
                          <div className="customer-testi">
                            <img
                              src={client06}
                              className="img-fluid avatar avatar-small rounded-circle shadow"
                              alt="Landrick"
                            />
                            <p className="text-muted mt-4">
                              " According to most sources, Lorum Ipsum can be
                              traced back to a text composed by Cicero. "
                            </p>
                            <h6 className="text-primary">- Christa Smith</h6>
                          </div>
                        ) : null}
                      </Row>
                    </div>
                  </div>
                  <div className="tns-nav">
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          step1: true,
                          step2: false,
                          step3: false,
                          step4: false,
                          step5: false,
                          step6: false,
                        });
                      }}
                      className={
                        this.state.step1
                          ? "tns-nav-active"
                          : "tns-nav-inactive"
                      }
                    ></button>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          step1: false,
                          step2: true,
                          step3: false,
                          step4: false,
                          step5: false,
                          step6: false,
                        });
                      }}
                      className={
                        this.state.step2
                          ? "tns-nav-active"
                          : "tns-nav-inactive"
                      }
                    ></button>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          step1: false,
                          step2: false,
                          step3: true,
                          step4: false,
                          step5: false,
                          step6: false,
                        });
                      }}
                      className={
                        this.state.step3
                          ? "tns-nav-active"
                          : "tns-nav-inactive"
                      }
                    ></button>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          step1: false,
                          step2: false,
                          step3: false,
                          step4: true,
                          step5: false,
                          step6: false,
                        });
                      }}
                      className={
                        this.state.step4
                          ? "tns-nav-active"
                          : "tns-nav-inactive"
                      }
                    ></button>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          step1: false,
                          step2: false,
                          step3: false,
                          step4: false,
                          step5: true,
                          step6: false,
                        });
                      }}
                      className={
                        this.state.step5
                          ? "tns-nav-active"
                          : "tns-nav-inactive"
                      }
                    ></button>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          step1: false,
                          step2: false,
                          step3: false,
                          step4: false,
                          step5: false,
                          step6: true,
                        });
                      }}
                      className={
                        this.state.step6
                          ? "tns-nav-active"
                          : "tns-nav-inactive"
                      }
                    ></button>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col
              lg={{ size: 5, order: 1 }}
              xs={{ order: 2 }}
              className="mt-4 pt-2 mt-lg-0 pt-lg-0"
            >
              <div className="section-title me-lg-4">
                <h1 className="title mb-3">
                  Tenant Portal
                </h1>
                <p className="para-desc text-muted">
                  Manage your leases with the Zenstrin app.
                </p>

                <Row>
                  {tenantFeatures.map((facility, key) => (
                    <Col xs="12" key={key}>
                      <div className="d-flex align-items-center pt-4">
                        <h2>
                          <i>
                            <FeatherIcon
                              icon={facility.icon}
                              className="fea icon-m-md "
                              style={{ color: "#f7961c" }}
                            />
                          </i>
                        </h2>
                        <div className="ms-3">
                          <h5> {facility.title} </h5>
                          <p className="text-muted mb-0">{facility.desc}</p>
                        </div>
                      </div>
                    </Col>
                  ))}

                  <Col xs="12" className="pt-4">
                    <Link to="/tenants" className="btn btn-pills btn-outline-primary"
                    // onClick={this.props.onDrawerOpen} disabled={this.props.isDrawerOpen}
                    >
                      Learn More <i className="uil uil-angle-right-b"></i>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
              <div className=" position-relative">
                <img
                  src={this.props.image2}
                  className="img-fluid mx-auto d-block rounded "
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* <Container className="pb-5 mb-md-5 mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="saas-feature-shape-left position-relative">
                <img
                  src={this.props.image3}
                  className="img-fluid mx-auto d-block rounded shadow"
                  alt=""
                />
              </div>
            </Col>

            <Col lg={5} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
              <div className="section-title ms-lg-4">
                <h1 className="title mb-3">
                  Property Development Solution
                </h1>
                <p className="para-desc text-muted">
                  Manage your construction project from anywhere with ease.
                </p>

                <Row>
                  {propertyDevelopmentFeatures.map((facility, key) => (
                    <Col xs="12" key={key}>
                      <div className="d-flex align-items-center pt-4">
                        <h2>
                          <i>
                            <FeatherIcon
                              icon={facility.icon}
                              className="fea icon-m-md "
                              style={{ color: "#f7961c" }}
                            />
                          </i>
                        </h2>
                        <div className="ms-3">
                          <h5> {facility.title} </h5>
                          <p className="text-muted mb-0">{facility.desc}</p>
                        </div>
                      </div>
                    </Col>
                  ))}

                  <Col xs="12" className="pt-4">
                    <Link to="/property-developers" className="btn btn-outline-primary"
                    // onClick={this.props.onDrawerOpen} disabled={this.props.isDrawerOpen}
                    >
                      Learn More <i className="uil uil-angle-right-b"></i>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container> */}
      </React.Fragment>
    );
  }
}

FeatureDescription.propTypes = {
  onDrawerOpen: PropTypes.func,
  isDrawerOpen: PropTypes.bool
};

export default FeatureDescription;
