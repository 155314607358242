// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Label,
  Card,
  CardBody,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { httpsCallable } from "firebase/functions";
import { functions } from '../../../firebase/firebase';
import DownloadResource from "../../../components/Layout/DownloadResource";
// import Footer7 from "../../../pages/Pages/PageFooterLayouts/Footer7";
// import ThemeSwitcher from "../../../components/Layout/ThemeSwitcher";

class PropertyIncomeExpenseSpreadsheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Contactvisible: false,
      resource: {
        name: "Property Income and Expense Spreadsheet",
        url: "https://docs.google.com/spreadsheets/d/1KP0UwjRdJ1p97WdU9V-y9lis3YwJArt56DoXb7uyAAE/copy",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
    const name = document.getElementById("name").value;
    const email = document.getElementById("userEmail").value;

    // console.log(name);
    // console.log(email);

    const resource = {
      name: "Property Income and Expense Spreadsheet",
      url: "https://docs.google.com/spreadsheets/d/1KP0UwjRdJ1p97WdU9V-y9lis3YwJArt56DoXb7uyAAE/copy",
    };

    const sendResource = httpsCallable(functions, 'sendResource');
    sendResource({ email: email, name: name, resource: resource, })
      .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // const sanitizedMessage = data.text;
      });
  }

  componentDidMount() {
    document.getElementById("topnav").classList.add("bg-white");
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  sendMail() {
    window.location.href = "mailto:support@zenstrin.com";
  }

  callNumber() {
    window.location.href = "tel:+152534-468-854";
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-5 mt-4">
          {/* <Container fluid>
            <Row>
              <Col xs={12} className="p-0">
                <Card className=" map border-0">
                  <CardBody className="p-0">
                    <iframe
                      title="Landrick"
                      src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      style={{ border: 0 }}
                      allowFullScreen
                    ></iframe>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container> */}

          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col
                lg={5}
                md={{ size: 6, order: 1 }}
                xs={{ order: 2 }}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <DownloadResource resource={this.state.resource} />
              </Col>

              <Col lg={7} md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
                <div className="title-heading ms-lg-4">
                  <h4 className="mb-4">Property Income and Expense Spreadsheet</h4>
                  <p className="text-muted">
                    Download{" "}
                    <span className="text-warning fw-bold">
                      Zenstrin's
                    </span>{" "}
                    Property Income and Expense Spreadsheet. The resource will be sent to your email.
                  </p>
                </div>
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    <img src="https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fresources%2Fproperty-income-expense.avif?alt=media&token=41e72f74-b64e-4efa-8ed1-52104d2a79e5" className="d-block w-100" alt="..." />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <Footer7/> */}
      </React.Fragment>
    );
  }
}

export default PropertyIncomeExpenseSpreadsheet;