import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Images
import bgimg from "../../../../assets/images/1.jpg";
import Newsletter from "../../../../components/Layout/Newsletter";

export default class WhatDoesATenantsDepositCover extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu").classList.add("nav-light");
    })
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".settingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".settingbtn").classList.remove("btn-soft-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      // document.querySelector(".settingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" style={{ background: `url(${bgimg}) center center` }}>
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h3 className="title text-white title-dark mb-0"> What Does a Tenant's <br />Deposit Cover? </h3>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="#">Blog</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">What does a tenant's deposit cover?</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon social mb-0">
                      <li className="mb-2 h6">Share</li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.instagram.com/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.linkedin.com/company/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                  <div className="col-md-10">

                    <ul className="list-unstyled d-flex justify-content-between mt-4">
                      <li className="list-inline-item user me-2">
                        <Link to="#" className="text-muted">
                          <i className="uil uil-user text-dark"></i> Zenstrin
                        </Link>
                      </li>
                      <li className="list-inline-item date text-muted">
                        <i className="uil uil-calendar-alt text-dark"></i>{" "}
                        20th March, 2024
                      </li>
                    </ul>

                    <img
                      src={"https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fwhat-does-a-tenants-deposit-cover.jpg?alt=media&token=6d71f626-5a2b-4027-be20-26c8c172dcaa"}
                      className="img-fluid rounded-md shadow"
                      alt=""
                    />

                    <h5 className="mt-4">
                      What Does a Tenant's Deposit Cover?
                    </h5>

                    <p className="text-muted">
                      When renting a property, tenants are typically required to pay a security deposit to the landlord or letting agency. This deposit serves as a form of financial protection for the landlord, covering any potential damages to the property or unpaid rent at the end of the tenancy. However, many tenants may not fully understand what their deposit covers and how it is used. In this article, we'll explore in detail what a tenant's deposit covers and provide clarity on this important aspect of renting.
                    </p>

                    <h5 className="mt-4">
                      Security against damages
                    </h5>

                    <p className="text-muted">
                      One of the primary purposes of a tenant's deposit is to provide security for the landlord against damages to the property beyond normal wear and tear. Normal wear and tear refers to the gradual deterioration of the property that occurs over time due to ordinary use. Examples of normal wear and tear include minor scuffs on walls, worn carpets, and fading paint.
                      However, any damages caused by the tenant or their guests that go beyond normal wear and tear may be deducted from the deposit at the end of the tenancy. These damages can include broken appliances, holes in walls, stains on carpets, or any other damage that requires repair or replacement.
                    </p>

                    <h5 className="mt-4">
                      Unpaid rent
                    </h5>

                    <p className="text-muted">
                      Another important aspect of a tenant's deposit is to cover any unpaid rent at the end of the tenancy. If the tenant falls behind on rent payments during the tenancy and fails to settle the outstanding balance before moving out, the landlord may deduct the unpaid rent from the deposit.

                      It's essential for tenants to fulfill their rental obligations by paying rent on time to avoid deductions from their deposit. Keeping track of rent payments and communicating with the landlord or letting agency in case of financial difficulties can help prevent issues with unpaid rent.
                    </p>

                    <h5 className="mt-4">
                      Cleaning and maintenance
                    </h5>

                    <p className="text-muted">
                      A tenant's deposit may also be used to cover the cost of cleaning the property to return it to its original condition at the end of the tenancy. Tenants are typically expected to leave the property in a clean and tidy state, including cleaning appliances, bathrooms, and communal areas.

                      If the property requires professional cleaning or maintenance services beyond what is considered reasonable, the cost may be deducted from the deposit. It's essential for tenants to understand their responsibilities regarding cleaning and maintenance to avoid disputes over deposit deductions.
                    </p>

                    <h5 className="mt-4">
                      Dispute resolution
                    </h5>

                    <p className="text-muted">
                      In case of disagreements between the landlord and tenant regarding deposit deductions, both parties have the option to use a tenancy deposit protection scheme to resolve the dispute. These schemes provide an independent adjudication service to help resolve disputes over deposit deductions fairly and impartially.

                      Tenants should ensure that their deposit is protected by a government-approved scheme throughout the tenancy. This helps safeguard their deposit and provides recourse in case of disputes at the end of the tenancy.
                    </p>

                    <h5 className="mt-4">
                      Breach of tenancy agreement
                    </h5>

                    <p className="text-muted">
                      In addition to damages, unpaid rent, and cleaning costs, a tenant's deposit may also cover any other breaches of the tenancy agreement. This can include violations such as unauthorized subletting, keeping pets without permission, or failing to adhere to noise restrictions.

                      If the tenant breaches the terms of the tenancy agreement and causes financial loss or inconvenience to the landlord, the cost of addressing the breach may be deducted from the deposit. It's crucial for tenants to familiarize themselves with the terms of their tenancy agreement and comply with all requirements to avoid potential deductions from their deposit.
                    </p>

                    <h5 className="mt-4">
                      Conclusion
                    </h5>

                    <p className="text-muted">
                      In conclusion, a tenant's deposit serves as a form of financial security for the landlord and covers various aspects of the tenancy agreement. It protects against damages to the property, unpaid rent, cleaning and maintenance costs, and breaches of the tenancy agreement. Tenants should understand what their deposit covers and fulfill their responsibilities to avoid disputes over deposit deductions. By maintaining open communication with the landlord or letting agency and adhering to the terms of the tenancy agreement, tenants can ensure a smooth and hassle-free rental experience.
                    </p>
                    <Newsletter />
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
