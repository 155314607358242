import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import ReactDrawer from 'react-drawer';

//Import Components
import RightSidebar from "../../components/Layout/RightSidebar";
import Section from "./section";
import TopSection from "./TopSection";
// import Partners from "./Partners";
import Feature from "../../components/Shared/Feature";
import FeatureDescription from "./FeatureDescription";
// import CTA from "./cta";
// import Pricings from "./pricings";
import Trial from "../../components/Shared/Trial";
import { featureArray,  testimonials } from "../../common/data";
import classic02 from "../../assets/images/saas/classic02.png";
import myclassic1 from "../../assets/images/property-management/myclassic1.png";
import myclassic2 from "../../assets/images/property-management/myclassic2.png";
import myclassic3 from "../../assets/images/property-management/myclassic3.png";
import myclassic4 from "../../assets/images/property-management/myclassic4.png";
import myclassic5 from "../../assets/images/property-management/myclassic5.png";
import myclassic6 from "../../assets/images/property-management/myclassic6.png";
// import Popup from "../../components/Layout/popup";

//Import Images

const facilities = [
  {
    icon: "tool",
    title: "Issue management",
    desc:
      "Tenants create raise issues online, use AI to handle issues.",
  },
  {
    icon: "file-text",
    title: "Service partners & Jobs",
    desc:
      "Schedule jobs for service partners or allow our AI to schedule jobs.",
  },
];

const Features = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
    });
    window.addEventListener("scroll", scrollNavigation, true);
  })

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".settingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document.querySelector(".settingbtn")?.classList.remove("btn-soft-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.querySelector(".settingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      {/* render section */}
      <TopSection onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} />
      {/* <Section onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} /> */}

      {/* render partner */}
      {/* <Partners /> */}

      <section className="section overflow-hidden bg-light">
        <Container className="pb-5 mb-md-5">
          {/* feature box */}
          <Feature featureArray={featureArray} isCenter={true} />
        </Container>

        {/* Render Feature Description */}
        <FeatureDescription
          image1={myclassic1}
          image2={myclassic2}
          image3={myclassic3}
          image4={myclassic4}
          image5={myclassic5}
          image6={myclassic6}
          testimonials={testimonials}
          facilities={facilities}
          onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} />
        {/* <CTA /> */}
        {/* <Pricings /> */}
        <Trial onDrawerOpen={toggleDrawer} isDrawerOpen={isDrawerOpen} />
      </section>

      {/* <Popup/> */}

      {/* <RequestDemo toggleModal={toggleModal} isOpen={isModalOpen}/> */}
      <ReactDrawer
        open={isDrawerOpen}
        position={"right"}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  );
}

export default Features;
