import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import AccordianCommon from "../../components/Shared/AccordianCommon";

export default class Questions extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="section-title text-md-start text-center">
                <h4 className="title mb-4">You have any questions ?</h4>
                <p className="text-muted mb-0 para-desc">
                  Download the{" "}
                  <span className="text fw-bold" style={{color: "#f7961c"}}>
                    Zenstrin
                  </span>{" "}
                  app that can provides everything you need.
                </p>

                {/* <ul className="list-unstyled text-muted mt-4 mb-0">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Our Talented & Experienced Marketing Agency
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                    Create your own skin to match your brand
                  </li>
                </ul> */}
              </div>
            </Col>

            <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="faq-content">
                <div className="accordion" id="accordionExampleone">
                  <AccordianCommon
                    question1="How do I get started?"
                    answer1="Once you are added to a lease you will be sent login credentials or you can dowmload the app, create your account and find a property."
                    question2="I can't see my lease?"
                    answer2="Once you have been added to a lease, it will be visibile in the app."
                    question3="Can I pay my rent?"
                    answer3="Yes, simply check your outstanding balance and click pay now."
                    question4="Can I make maintenance requests?"
                    answer4="Yes you can raise issues from the app. Once an issue has been raised you will be contacted if further information is needed."
                    question5="Can I have multiple leases?"
                    answer5="Yes you can have multiple leases from multiple landlords."
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className="row mt-5 pt-md-4 justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title">
                <h4 className="title mb-4">Have Question ? Get in touch!</h4>
                {/* <p className="text-muted para-desc mx-auto">
                  Start working with{" "}
                  <span className="text-primary fw-bold">
                    Landrick
                  </span>{" "}
                  that can provide everything you need to generate awareness,
                  drive traffic, connect.
                </p> */}
                <Link
                  to="/contact"
                  className="btn btn-pills btn-primary mt-4"
                >
                  <i className="mdi mdi-phone"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
