// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Label,
  Card,
  CardBody,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { httpsCallable } from "firebase/functions";
import { functions } from '../../../firebase/firebase';
// import Footer7 from "../../../pages/Pages/PageFooterLayouts/Footer7";
// import ThemeSwitcher from "../../../components/Layout/ThemeSwitcher";

class RentRenewalTermsAndAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
    const name = document.getElementById("name").value;
    const email = document.getElementById("userEmail").value;

    // console.log(name);
    // console.log(email);

    const resource = {
      name: "Rent Renewal Terms And Agreement",
      url: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/resources%2FRent%20renewal%20terms%20and%20agreement.pdf?alt=media&token=43847111-bf5d-4698-a9c8-b670d8802acc",
    };

    const sendResource = httpsCallable(functions, 'sendResource');
    sendResource({email: email, name: name, resource: resource,})
    .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // const sanitizedMessage = data.text;
    });
  }

  componentDidMount() {
    document.getElementById("topnav").classList.add("bg-white");
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  sendMail() {
    window.location.href = "mailto:support@zenstrin.com";
  }

  callNumber() {
    window.location.href = "tel:+152534-468-854";
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-5 mt-4">
          {/* <Container fluid>
            <Row>
              <Col xs={12} className="p-0">
                <Card className=" map border-0">
                  <CardBody className="p-0">
                    <iframe
                      title="Landrick"
                      src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      style={{ border: 0 }}
                      allowFullScreen
                    ></iframe>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container> */}

          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col
                lg={5}
                md={{ size: 6, order: 1 }}
                xs={{ order: 2 }}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <Card className="custom-form rounded shadow border-0">
                  <CardBody>
                    <div id="message"></div>
                    <Alert
                      color="success"
                      isOpen={this.state.Contactvisible}
                      toggle={() => {
                        this.setState({
                          Contactvisible: !this.state.Contactvisible,
                        });
                      }}
                    >
                      Your resource has been sent to your email.
                    </Alert>
                    <Form
                      method="post"
                      onSubmit={this.handleSubmit}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Name <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="user"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control ps-5"
                              placeholder="First Name :"
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="mail"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="email"
                              id="userEmail"
                              type="email"
                              className="form-control ps-5"
                              placeholder="Email :"
                              required
                            />
                          </div>
                        </Col>
                        {/* <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">Subject</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="book"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <Input
                              name="subject"
                              id="subject"
                              className="form-control ps-5"
                              placeholder="Subject :"
                              required
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label className="form-label">Comments</Label>
                            <div className="form-icon position-relative">
                              <i>
                                <FeatherIcon
                                  icon="message-circle"
                                  className="fea icon-sm icons"
                                />
                              </i>
                            </div>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              className="form-control ps-5"
                              placeholder="Message :"
                              required
                            ></textarea>
                          </div>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col sm="12" className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary btn-block"
                              value="Download"
                            />
                          </div>
                          <div id="simple-msg"></div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={7} md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
                <div className="title-heading ms-lg-4">
                  <h4 className="mb-4">Rent Renewal Terms And Agreement</h4>
                  <p className="text-muted">
                    Download{" "}
                    <span className="text-warning fw-bold">
                      Zenstrin's
                    </span>{" "}
                    Rent Renewal Terms And Agreement. The resource will be sent to your email.
                  </p>
                  {/* <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="mail"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h6 className="title fw-bold mb-0">Email</h6>
                      <Link
                        to="#"
                        onClick={this.sendMail}
                        className="text-warning "
                      >
                        support@zenstrin.com
                      </Link>
                    </div>
                  </div> */}

                  {/* <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="phone"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h6 className="title fw-bold mb-0">Phone</h6>
                      <Link
                        to="#"
                        onClick={this.callNumber}
                        className="text-warning "
                      >
                        +152 534-468-854
                      </Link>
                    </div>
                  </div> */}

                  {/* <div className="d-flex contact-detail align-items-center mt-3">
                    <div className="icon">
                      <i>
                        <FeatherIcon
                          icon="map-pin"
                          className="fea icon-m-md text-dark me-3"
                        />
                      </i>
                    </div>
                    <div className="flex-1 content">
                      <h6 className="title fw-bold mb-0">Location</h6>
                      <Link to="#" className="video-play-icon  text-warning">
                        View on Google map
                      </Link>
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <Footer7/> */}
      </React.Fragment>
    );
  }
}

export default RentRenewalTermsAndAgreement;