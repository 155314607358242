import React from 'react';
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";

import FeatherIcon from "feather-icons-react";
import { httpsCallable } from "firebase/functions";
import { functions } from '../../firebase/firebase';

const Newsletter = () => {

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = document.getElementById('email-newsletter').value;
    const firstName = document.getElementById('first-name-newsletter').value;
    const lastName = document.getElementById('last-name-newsletter').value;

    if (!email || !firstName || !lastName) {
      alert('Please fill in all fields');
      return;
    }

    // reset form
    document.getElementById('form-newsletter').reset();

    const addToNewsletter = httpsCallable(functions, 'addToNewsletter');
    addToNewsletter({ email, firstName, lastName })
      .then((result) => {
        // Read result of the Cloud Function.
        // /** @type {any} */
        // const data = result.data;
        // const sanitizedMessage = data.text;
      });
    alert('You have subscribed to our newsletter!')
  }

  return (
    <React.Fragment>
      <Card className="custom-form rounded shadow border-0">
        <CardBody>
          <h5 className="mt-4">Subscribe to our newsletter:</h5>

          <Form className="mt-3" onSubmit={handleSubmit} id="form-newsletter">
            <Row>

              <div className="col-lg-6">
                <div className="mb-3">
                  <Label className="form-label">
                    First Name <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="user"
                      className="fea icon-sm icons"
                    />
                    <Input
                      id="first-name-newsletter"
                      name="name"
                      type="text"
                      placeholder="First Name"
                      className="form-control ps-5"
                      required=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <Label className="form-label">
                    Last Name <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="user"
                      className="fea icon-sm icons"
                    />
                    <Input
                      id="last-name-newsletter"
                      name="name"
                      type="text"
                      placeholder="Name"
                      className="form-control ps-5"
                      required=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <Label className="form-label">
                    Your Email <span className="text-danger">*</span>
                  </Label>
                  <div className="form-icon position-relative">
                    <FeatherIcon
                      icon="mail"
                      className="fea icon-sm icons"
                    />
                    <Input
                      id="email-newsletter"
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control ps-5"
                      required=""
                    />
                  </div>
                </div>
              </div>

              <Col md={12}>
                <div className="send d-grid">
                  <Button
                    type="submit"
                    className="btn"
                    color="primary"
                  >
                    Subscribe
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Newsletter;