import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Alert, UncontrolledAlert, Form } from 'reactstrap';
import PropTypes from 'prop-types';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//import images
import logodark from '../../assets/images/zenstrin-logo.png';
import logolight from '../../assets/images/zenstrin-logo-white.png';
// import contact from "../../assets/images/contact.svg";
import { Link } from 'react-router-dom';
import { httpsCallable } from "firebase/functions";
import { functions, analytics, logEvent } from '../../firebase/firebase';

class RightSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSuccess: false,
            isError: false,
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            customerType: 'Property Manager',
            city: '',
            properties: '',
            beds: '',
            baths: '',
        };
        this.onCloseRightBar = this.onCloseRightBar.bind(this);
        this.onRequestDemo = this.onRequestDemo.bind(this);
    }
    onCloseRightBar = (e) => {
        e.preventDefault();
        this.props.onClose();
    };

    onRequestDemo = (e) => {
        e.preventDefault();
        if (this.state.customerType === 'Landlord') {
            if (this.state.name && this.state.email && this.state.city && this.state.properties) {
                // logEvent(analytics, 'demo_requested');
                this.setState({ isSuccess: true });
                this.setState({ isError: false });
                const addToWaitingList = httpsCallable(functions, 'addToWaitingList');
                addToWaitingList({ email: this.state.email, name: this.state.name, city: this.state.city, customerType: this.state.customerType, properties: this.state.properties })
                    .then((result) => {
                    });
                // const newDemoRequest = httpsCallable(functions, 'newDemoRequest');
                // newDemoRequest({ email: this.state.email, firstName: this.state.firstName, lastName: this.state.lastName, companyName: this.state.companyName, })
                //     .then((result) => {
                //         // Read result of the Cloud Function.
                //         // /** @type {any} */
                //         // const data = result.data;
                //         // const sanitizedMessage = data.text;
                //     });
                if (typeof gtag_report_conversion === 'function') {
                    gtag_report_conversion();
                } else {
                    console.error('gtag_report_conversion function is not available.');
                }
                // reset form
                document.getElementById('form-waitlist').reset();
                // window.open("https://calendly.com/ardenelegbe/zenstrin-demo", '_blank');
            } else {
                // console.log("Please fill in all details");
                this.setState({ isError: true });
                this.setState({ isSuccess: false });
            }
        } else if (this.state.customerType === 'Tenant') {
            if (this.state.name && this.state.email && this.state.city && this.state.beds && this.state.baths) {
                this.setState({ isSuccess: true });
                this.setState({ isError: false });

                const addToWaitingList = httpsCallable(functions, 'addToWaitingList');
                addToWaitingList({ email: this.state.email, name: this.state.name, city: this.state.city, customerType: this.state.customerType, beds: this.state.beds, baths: this.state.baths })
                    .then((result) => {
                    });

                if (typeof gtag_report_conversion === 'function') {
                    gtag_report_conversion();
                } else {
                    console.error('gtag_report_conversion function is not available.');
                }
                // reset form
                document.getElementById('form-waitlist').reset();
            } else {
                this.setState({ isError: true });
                this.setState({ isSuccess: false });
                // reset form
                document.getElementById('form-waitlist').reset();
            }
        } else if (this.state.customerType === 'Property Manager') {
            if (this.state.name && this.state.email && this.state.city && this.state.properties) {
                // logEvent(analytics, 'demo_requested');
                this.setState({ isSuccess: true });
                this.setState({ isError: false });
                const addToWaitingList = httpsCallable(functions, 'addToWaitingList');
                addToWaitingList({ email: this.state.email, name: this.state.name, city: this.state.city, customerType: this.state.customerType, properties: this.state.properties })
                    .then((result) => {
                    });
                // const newDemoRequest = httpsCallable(functions, 'newDemoRequest');
                // newDemoRequest({ email: this.state.email, firstName: this.state.firstName, lastName: this.state.lastName, companyName: this.state.companyName, })
                //     .then((result) => {
                //         // Read result of the Cloud Function.
                //         // /** @type {any} */
                //         // const data = result.data;
                //         // const sanitizedMessage = data.text;
                //     });
                if (typeof gtag_report_conversion === 'function') {
                    gtag_report_conversion();
                } else {
                    console.error('gtag_report_conversion function is not available.');
                }
                // reset form
                document.getElementById('form-waitlist').reset();
                // window.open("https://calendly.com/ardenelegbe/zenstrin-demo", '_blank');
            } else {
                // console.log("Please fill in all details");
                this.setState({ isError: true });
                this.setState({ isSuccess: false });
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="custome-drawer offcanvas bg-white shadow show d-flex flex-column h-100">
                    <div className="offcanvas-header p-4 border-bottom">
                        <h5 id="offcanvasRightLabel" className="mb-0">
                            <img src={logodark} height="40" className="light-version" alt="" />
                            <img src={logolight} height="40" className="dark-version" alt="" />
                        </h5>
                        <button type="button" onClick={this.onCloseRightBar} className="btn-close d-flex align-items-center text-dark">
                        </button>
                    </div>
                    <div className="offcanvas-body p-4">
                        <Form id="form-waitlist">
                            <Row>
                                <Col xs={12}>
                                    {/* <img src={contact} className="img-fluid d-block mx-auto" style={{ maxWidth: "256px" }} alt="" /> */}
                                    {(this.state.isError === true) && (<Alert color="danger"> Please fill out all details</Alert>)}
                                    {(this.state.isSuccess === true) && (<Alert color="success"> Thank you, we will be in touch soon</Alert>)}
                                    <Card className="border-0 mt-5" style={{ zIndex: "1" }}>
                                        <CardBody className="p-0">
                                            <div name="myForm">
                                                <p id="error-msg" className="mb-0"></p>
                                                <div id="simple-msg"></div>
                                                <Row>
                                                    {/* <Col md={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Your First Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FeatherIcon
                                                                icon="user"
                                                                className="fea icon-sm icons"
                                                            />
                                                            <input name="name" id="firstName" type="text" className="form-control ps-5" placeholder="Name :" onChange={(val) => this.setState({ firstName: val.target.value })} />
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                    {/* <Col md={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Your Last Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FeatherIcon
                                                                icon="user"
                                                                className="fea icon-sm icons"
                                                            />
                                                            <input name="name" id="lastName" type="text" className="form-control ps-5" placeholder="Name :" onChange={(val) => this.setState({ lastName: val.target.value })} />
                                                        </div>
                                                    </div>
                                                </Col> */}

                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Name <span className="text-danger">*</span></label>
                                                            <div className="form-icon position-relative">
                                                                <FeatherIcon
                                                                    icon="user"
                                                                    className="fea icon-sm icons"
                                                                />
                                                                <input name="name" id="name" type="text" className="form-control ps-5" placeholder="Name :" onChange={(val) => this.setState({ name: val.target.value })} />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                                            <div className="form-icon position-relative">
                                                                <FeatherIcon
                                                                    icon="mail"
                                                                    className="fea icon-sm icons"
                                                                />
                                                                <input name="email" id="email" type="email" className="form-control ps-5" placeholder="Email :" onChange={(val) => this.setState({ email: val.target.value })} />
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col xs={12}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Landlord, Tenant or Property Manager <span className="text-danger">*</span></label>
                                                            <div className="form-icon position-relative">
                                                                <select
                                                                    name="landlord"
                                                                    id="customer-type"
                                                                    className="form-select ps-5"
                                                                    required
                                                                    onChange={(val) => this.setState({ customerType: val.target.value })}
                                                                >
                                                                    <option value="Property Manager">Property Manager</option>
                                                                    <option value="Landlord">Landlord</option>
                                                                    <option value="Tenant">Tenant</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    {this.state.customerType === 'Landlord' && (
                                                        <>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">What city are your properties located <span className="text-danger">*</span></label>
                                                                    <div className="form-icon position-relative">
                                                                        <input name="city" id="city" type="text" className="form-control ps-5" placeholder="City :" onChange={(val) => this.setState({ city: val.target.value })} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">How many properties do you own? <span className="text-danger">*</span></label>
                                                                    <div className="form-icon position-relative">
                                                                        <input name="properties" id="properties" type="number" className="form-control ps-5" placeholder="Properties :" onChange={(val) => this.setState({ properties: val.target.value })} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )}

                                                    {this.state.customerType === 'Property Manager' && (
                                                        <>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">What city are your properties located <span className="text-danger">*</span></label>
                                                                    <div className="form-icon position-relative">
                                                                        <input name="city" id="city" type="text" className="form-control ps-5" placeholder="City :" onChange={(val) => this.setState({ city: val.target.value })} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">How many properties do you manage? <span className="text-danger">*</span></label>
                                                                    <div className="form-icon position-relative">
                                                                        <input name="properties" id="properties" type="number" className="form-control ps-5" placeholder="Properties :" onChange={(val) => this.setState({ properties: val.target.value })} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )}

                                                    {this.state.customerType === 'Tenant' && (
                                                        <>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">What city are you looking for accomodation? <span className="text-danger">*</span></label>
                                                                    <div className="form-icon position-relative">
                                                                        <input name="city" id="city" type="text" className="form-control ps-5" placeholder="City :" onChange={(val) => this.setState({ city: val.target.value })} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Bedrooms <span className="text-danger">*</span></label>
                                                                    <div className="form-icon position-relative">
                                                                        <input name="beds" id="beds" type="number" className="form-control ps-5" placeholder="Beds :" onChange={(val) => this.setState({ beds: val.target.value })} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="mb-3">
                                                                    <label className="form-label">Bathrooms <span className="text-danger">*</span></label>
                                                                    <div className="form-icon position-relative">
                                                                        <input name="baths" id="baths" type="number" className="form-control ps-5" placeholder="Baths :" onChange={(val) => this.setState({ baths: val.target.value })} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )}

                                                    {/* <Col xs={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Company <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FeatherIcon
                                                                icon="book"
                                                                className="fea icon-sm icons"
                                                            />
                                                            <input name="company" id="companyName" className="form-control ps-5" placeholder="Company Name :" onChange={(val) => this.setState({ companyName: val.target.value })} />
                                                        </div>
                                                    </div>
                                                </Col> */}

                                                    {/* <Col xs={12}>
                                                    <div className="mb-3">
                                                        <label className="form-label">Comments <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FeatherIcon
                                                                icon="message-circle"
                                                                className="fea icon-sm icons clearfix"
                                                            />
                                                            <textarea name="comments" id="comments" rows="4" className="form-control ps-5" placeholder="Message :"></textarea>
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <div className="d-grid">
                                                            <button onClick={this.onRequestDemo} name="request" className="btn btn-primary">Request Demo</button>
                                                        </div>
                                                    </Col>

                                                    <Col xs={12}>
                                                        <div className="d-grid">
                                                            <button onClick={this.onCloseRightBar} name="close" className="btn btn-soft-dark" style={{ marginTop: 20 }}>Close</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <div className="offcanvas-footer p-4 border-top text-center mt-auto">
                        <ul className="list-unstyled social-icon social mb-0">
                            {/* <li className="list-inline-item"><Link to="//1.envato.market/landrickreactjs" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-shopping-cart align-middle" title="Buy Now"></i></Link></li>{" "}
                            <li className="list-inline-item"><Link to="//dribbble.com/Themesbrand" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-dribbble align-middle" title="dribbble"></i></Link></li>{" "}
                            <li className="list-inline-item"><Link to="//www.behance.net/Themesbrand" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-behance align-middle" title="behance"></i></Link></li>{" "}
                            <li className="list-inline-item"><Link to="//www.facebook.com/Themesbrand" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>{" "} */}
                            <li className="list-inline-item"><Link to="//instagram.com/zenstrin/" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-instagram align-middle" title="instagram"></i></Link></li>{" "}
                            <li className="list-inline-item"><Link to="//www.linkedin.com/company/zenstrin/" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-linkedin align-middle" title="linkedin"></i></Link></li>{" "}
                            {/* <li className="list-inline-item"><Link to="//twitter.com/Themesbrand" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-twitter align-middle" title="twitter"></i></Link></li>{" "} */}
                            {/* <li className="list-inline-item"><Link to="mailto:support@Themesbrand.in" className="rounded"><i className="uil uil-envelope align-middle" title="email"></i></Link></li>{" "} */}
                            {/* <li className="list-inline-item"><Link to="//Themesbrand.in" target="_blank" rel="noreferrer" className="rounded"><i className="uil uil-globe align-middle" title="website"></i></Link></li>{" "} */}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

RightSidebar.propTypes = {
    onClose: PropTypes.func
};

export default RightSidebar;