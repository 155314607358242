import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Images
import bgimg from "../../../../assets/images/1.jpg";
import Newsletter from "../../../../components/Layout/Newsletter";

export default class FiveThingsToConsiderWhenReviewingARentalApplication extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu").classList.add("nav-light");
    })
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".settingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".settingbtn").classList.remove("btn-soft-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      // document.querySelector(".settingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" style={{ background: `url(${bgimg}) center center` }}>
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h3 className="title text-white title-dark mb-0">
                    Five Things to Consider When <br />Reviewing a Rental Application </h3>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="#">Blog</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">Five Things to Consider When Reviewing a Rental Application</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon social mb-0">
                      <li className="mb-2 h6">Share</li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.instagram.com/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.linkedin.com/company/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                  <div className="col-md-10">

                    <ul className="list-unstyled d-flex justify-content-between mt-4">
                      <li className="list-inline-item user me-2">
                        <Link to="#" className="text-muted">
                          <i className="uil uil-user text-dark"></i> Zenstrin
                        </Link>
                      </li>
                      <li className="list-inline-item date text-muted">
                        <i className="uil uil-calendar-alt text-dark"></i>{" "}
                        20th March, 2024
                      </li>
                    </ul>

                    <img
                      src={"https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Ffive-things-to-consider-when-reviewing-a-rental-application.jpg?alt=media&token=b2d1f3fe-c31b-4882-9b38-001d4b476523"}
                      className="img-fluid rounded-md shadow"
                      alt=""
                    />

                    <h5 className="mt-4">
                      Five Things to Consider When Reviewing a Rental Application
                    </h5>

                    <p className="text-muted">
                      Renting out a property is a significant decision, and choosing the right tenant is crucial for the success of any rental property investment. When reviewing rental applications, landlords and property managers need to consider various factors to ensure they select responsible and reliable tenants. In this article, we'll explore five essential things to consider when reviewing a rental application.
                    </p>

                    <h5 className="mt-4">
                      1. Financial Stability
                    </h5>

                    <p className="text-muted">
                      One of the most critical factors to consider when reviewing a rental application is the applicant's financial stability. Landlords and property managers want to ensure that prospective tenants have a steady income that is sufficient to cover the rent and other living expenses. When reviewing financial documents such as pay stubs, bank statements, and tax returns, look for evidence of consistent income and a debt-to-income ratio that is within a reasonable range.

                      Additionally, consider conducting a credit check to assess the applicant's credit history and financial behavior. A good credit score indicates that the applicant has a history of managing their finances responsibly, while a poor credit score may raise red flags about their ability to meet their financial obligations.
                    </p>

                    <h5 className="mt-4">
                      2. Rental History
                    </h5>

                    <p className="text-muted">
                      Another important consideration when reviewing a rental application is the applicant's rental history. Landlords and property managers should contact previous landlords or property management companies to inquire about the applicant's rental history, including their payment history, adherence to lease terms, and any issues with property damage or disruptive behavior.

                      By speaking with previous landlords, you can gain valuable insights into the applicant's reliability as a tenant and their likelihood of fulfilling their obligations under the lease agreement. Be sure to ask specific questions about the applicant's rental history and listen carefully to the landlord's feedback before making a decision.
                    </p>

                    <h5 className="mt-4">
                      3. Employment and References
                    </h5>

                    <p className="text-muted">
                      In addition to financial stability and rental history, landlords and property managers should consider the applicant's employment status and obtain references from employers and personal contacts. Verifying employment helps confirm the applicant's income and employment stability, while references provide insight into their character and reliability as a tenant.

                      When contacting employers, ask about the applicant's job stability, income level, and any relevant information that may affect their ability to pay rent consistently. Similarly, speaking with personal references such as friends, family members, or colleagues can help paint a more complete picture of the applicant's personality and lifestyle.
                    </p>

                    <h5 className="mt-4">
                      4. Criminal Background Check
                    </h5>

                    <p className="text-muted">
                      To ensure the safety and security of the rental property and other tenants, landlords and property managers should conduct a criminal background check as part of the application review process. A criminal background check can reveal any past criminal convictions or legal issues that may pose a risk to the property or other occupants.

                      When reviewing the results of a criminal background check, pay attention to the nature and severity of any offenses, as well as the applicant's explanation or mitigating circumstances. While minor infractions may not necessarily disqualify an applicant, serious criminal convictions may raise concerns about their suitability as a tenant.
                    </p>

                    <h5 className="mt-4">
                      Compatibility with Property Policies
                    </h5>

                    <p className="text-muted">
                      Finally, landlords and property managers should consider whether the applicant's lifestyle and behavior align with the policies and rules of the rental property. This includes factors such as pet ownership, smoking, noise levels, and occupancy limits.

                      Before approving a rental application, clearly communicate the property's rules and expectations to the applicant and ensure they are willing and able to comply with them. Clarify any questions or concerns they may have about the lease agreement or property policies to avoid misunderstandings later on.
                    </p>

                    <h5 className="mt-4">
                      Conclusion
                    </h5>

                    <p className="text-muted">
                      In conclusion, reviewing a rental application involves careful consideration of various factors, including financial stability, rental history, employment and references, criminal background, and compatibility with property policies. By thoroughly evaluating each applicant against these criteria, landlords and property managers can make informed decisions and select tenants who are likely to be responsible, reliable, and respectful of the rental property.
                    </p>

                    <Newsletter />
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
