// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Badge } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: "Zenstrin", link: "/" },
        { id: 2, name: "Resources", link: "#" },
      ],
    };
  }

  componentDidMount() {
    document.body.classList = "";
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> Resources </h4>
                </div>
              </div>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">Resources</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <div className="p-4 shadow rounded border">
                  <h5><span className="text-success"></span>Property Management<span className="badge rounded-pill bg-danger ms-2"></span></h5>
                  <ul className="list-unstyled mb-5">
                    <li className="text-muted my-2 ms-3">
                      <FeatherIcon icon="arrow-right-circle" className="fea icon-sm me-2"></FeatherIcon>
                      <span className="fw-bold">New : </span><b>Property Income and Expense Spreadsheet</b><Link to="/resources/property-income-expense-spreadsheet" target="_blank" className="h6 text-danger">{" "}Download</Link>
                    </li>
                    <li className="text-muted my-2 ms-3">
                      <FeatherIcon icon="arrow-right-circle" className="fea icon-sm me-2"></FeatherIcon>
                      <span className="fw-bold">New : </span><b>Maintenance Checklist</b><Link to="/resources/maintenance-checklist" target="_blank" className="h6 text-danger">{" "}Download</Link>
                    </li>
                    {/* <li className="text-muted my-2 ms-3">
                      <FeatherIcon icon="arrow-right-circle" className="fea icon-sm me-2"></FeatherIcon>
                      <span className="fw-bold">New : </span><b>Property Investment Analysis Spreadsheet</b><Link to="/resources/property-investment-analysis-spreadsheet" target="_blank" className="h6 text-danger">{" "}Download</Link>
                    </li> */}
                    <li className="text-muted my-2 ms-3">
                      <FeatherIcon icon="arrow-right-circle" className="fea icon-sm me-2"></FeatherIcon>
                      <span className="fw-bold">New : </span><b>Notice Of Entry</b><Link to="/resources/notice-of-entry" target="_blank" className="h6 text-danger">{" "}Download</Link>
                    </li>
                    <li className="text-muted my-2 ms-3">
                      <FeatherIcon icon="arrow-right-circle" className="fea icon-sm me-2"></FeatherIcon>
                      <span className="fw-bold">New : </span><b>Rent Renewal Reminder</b><Link to="/resources/rent-renewal-reminder" target="_blank" className="h6 text-danger">{" "}Download</Link>
                    </li>
                    <li className="text-muted my-2 ms-3">
                      <FeatherIcon icon="arrow-right-circle" className="fea icon-sm me-2"></FeatherIcon>
                      <span className="fw-bold">New : </span><b>Rent renewal terms and agreement</b><Link to="/resources/rent-renewal-terms-and-agreement" target="_blank" className="h6 text-danger">{" "}Download</Link>
                    </li>
                    <li className="text-muted my-2 ms-3">
                      <FeatherIcon icon="arrow-right-circle" className="fea icon-sm me-2"></FeatherIcon>
                      <span className="fw-bold">New : </span><b>Tenant Welcome Package</b><Link to="/resources/tenant-welcome-package" target="_blank" className="h6 text-danger">{" "}Download</Link>
                    </li>
                  </ul>

                  {/* <div className="mt-4">
                    <Link
                      to="https://1.envato.market/landrickreactjs"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Purchase Now
                    </Link>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Resources;
