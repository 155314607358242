// React Basic and Bootstrap
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";

// import images

class PageBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      blogs: [
        {
          id: 8,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fwhy-letting-agencies-should-embrace-spreadsheets-for-property-management.jpg?alt=media&token=d4c33bce-b1a4-49ec-970d-f8f735a83b69",
          title: "Why Letting Agencies Should Embrace Spreadsheets for Property Management",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "1st April, 2024",
          link: "/blog/why-letting-agencies-should-embrace-spreadsheets-for-property-management",
        },
        {
          id: 7,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Funderstanding-the-vital-role-of-a-student-tenant-guarantor-in-rental-agreements.jpg?alt=media&token=2f207fc1-ff94-40c1-afec-c71fe10b537f",
          title: "Understanding the Vital Role of a Student Tenant Guarantor in Rental Agreements",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "21st March, 2024",
          link: "/blog/understanding-the-vital-role-of-a-student-tenant-guarantor-in-rental-agreements",
        },
        {
          id: 6,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Foptimizing-tenant-rent-payments-choosing-the-best-payment-methods-for-landlords-and-tenants.jpg?alt=media&token=9acb9238-b58c-41bd-af85-9c8755df444c",
          title: "Optimizing Tenant Rent Payments: Choosing the Best Payment Methods for Landlords and Tenants",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "21st March, 2024",
          link: "/blog/optimizing-tenant-rent-payments-choosing-the-best-payment-methods-for-landlords-and-tenants",
        },
        {
          id: 5,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fmeeting-expectations-understanding-what-landlords-expect-from-letting-agencies.jpg?alt=media&token=f2da004c-2914-4354-9af2-33a5c157fcb7",
          title: "Meeting Expectations: Understanding What Landlords Expect from Letting Agencies",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "21st March, 2024",
          link: "/blog/meeting-expectations-understanding-what-landlords-expect-from-letting-agencies",
        },
        {
          id: 4,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fmaximizing-efficiency-harnessing-property-management-software-for-business-success.jpg?alt=media&token=a511203b-aa9a-4bee-9761-916bd74a078c",
          title: "Maximizing Efficiency: Harnessing Property Management Software for Business Success",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "20th March, 2024",
          link: "/blog/maximizing-efficiency-harnessing-property-management-software-for-business-success",
        },
        {
          id: 3,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fwhat-does-a-tenants-deposit-cover.jpg?alt=media&token=6d71f626-5a2b-4027-be20-26c8c172dcaa",
          title: "What Does a Tenant's Deposit Cover?",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "20th March, 2024",
          link: "/blog/what-does-a-tenants-deposit-cover",
        },
        {
          id: 2,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fhow-your-letting-agency-can-make-the-most-of-hybrid-working.jpg?alt=media&token=07479284-d8dc-4ee9-9d1b-4db903d066e7",
          title: "How Your Letting Agency Can Make the Most of Hybrid Working",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "20th March, 2024",
          link: "/blog/how-your-letting-agency-can-make-the-most-of-hybrid-working",
        },
        {
          id: 1,
          image: "https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Ffive-things-to-consider-when-reviewing-a-rental-application.jpg?alt=media&token=b2d1f3fe-c31b-4882-9b38-001d4b476523",
          title: "Five Things to Consider When Reviewing a Rental Application",
          like: "33",
          comment: "08",
          autor: "Zenstrin",
          date: "20th March, 2024",
          link: "/blog/five-things-to-consider-when-reviewing-a-rental-application",
        },
      ],
    };
  }

  componentDidMount() {
    document.body.classList = "";
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        // document.querySelector(".shoppingbtn").classList.add("btn-primary");
        // document.querySelector(".shoppingbtn").classList.remove("btn-light");
        // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> Blog </h4>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">Blog</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row>
              {/* blog box */}
              {this.state.blogs.map((blog, key) => (
                <Col lg="4" md="6" className="mb-4 pb-2" key={key} name="blog">
                  <Card className="blog rounded border-0 shadow overflow-hidden">
                    <div className="position-relative">
                      <CardImg
                        top
                        src={blog.image}
                        className="rounded-top"
                        alt=""
                      />
                      <div className="overlay rounded-top bg-dark"></div>
                    </div>
                    <CardBody className="content">
                      <h5>
                        <Link to={blog.link} className="card-title title text-dark">
                          {blog.title}
                        </Link>
                      </h5>
                      <div className="post-meta d-flex justify-content-between mt-3">
                        {/* <ul className="list-unstyled mb-0">
                          <li className="list-inline-item me-2  mb-0">
                            <Link to="#" className="text-muted like">
                              <i className="mdi mdi-heart-outline me-1"></i>
                              {blog.like}
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link to="#" className="text-muted comments">
                              <i className="uil uil-comment me-1"></i>
                              {blog.comment}
                            </Link>
                          </li>
                        </ul> */}
                        <Link
                          to={blog.link}
                          className="text-muted readmore"
                        >
                          Read More <i className="uil uil-angle-right-b"></i>
                        </Link>
                      </div>
                    </CardBody>
                    <div className="author">
                      <small className="text-light user d-block">
                        <i className="mdi mdi-account"></i> {blog.autor}
                      </small>
                      <small className="text-light date">
                        <i className="mdi mdi-calendar-check"></i> {blog.date}
                      </small>
                    </div>
                  </Card>
                </Col>
              ))}

              {/* <Col xs="12">
                <Pagination listClassName="justify-content-center mb-0">
                  <PaginationItem>
                    <PaginationLink to="#">
                      Prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink to="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink to="#">
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col> */}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageBlog;
