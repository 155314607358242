import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import tasks from "../../assets/images/property-management/myclassic2.png";
import rentalApplications from "../../assets/images/property-management/myclassic6.png";
import todo from "../../assets/images/ai-generated/todo.jpeg";
import propertyForRent from "../../assets/images/ai-generated/property-for-rent.jpeg";
import onboarding from "../../assets/images/ai-generated/onboarding.jpeg";

export default class HowItWorks extends Component {
  componentDidMount() {
    document.body.classList = "";
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn").classList.add("btn-primary");
        document.querySelector(".shoppingbtn").classList.remove("btn-light");
        document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="bg-half-100 bg-light d-table w-100">
          <Container>
            {/* <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> How it works </h4>
                </div>
              </Col>
            </Row> */}
            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="#">Landlords</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">How it works</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <div className="main-icon rounded-pill text-primary text-center mt-4 pt-2">
                  <i>
                    <FeatherIcon icon="star" className="fea icon-md-sm" style={{ color: "#fbcf97" }} />
                  </i>
                </div>
                <div className="timeline-page pt-2 position-relative">
                  <div className="timeline-item mt-4">
                    <Row>
                      <Col lg={6} md={6} sm={6}>
                        {/* <div className="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                          2002
                        </div> */}
                      </Col>
                      <Col lg={6} md={6} sm={6}>
                        <Card className="event event-description-right rounded shadow border-0 overflow-hidden float-start">
                          <img src={propertyForRent} className="img-fluid" alt="" />
                          <CardBody>
                            <h5 className="mb-0 text-capitalize">Set up rental properties</h5>
                            <p className="mt-3 mb-0 text-muted">
                              Upload your rental properties or import them from property portals.
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <div className="timeline-item mt-4">
                    <Row>
                      <Col lg={6} md={6} sm={6} className="order-sm-1 order-2">
                        <Card className="event event-description-left rounded shadow border-0 overflow-hidden float-start">
                          <img src={rentalApplications} className="img-fluid" alt="" />
                          <CardBody>
                            <h5 className="mb-0 text-capitalize">
                              Efficient Leasing with Powerful Tools
                            </h5>
                            <p className="mt-3 mb-0 text-muted">
                              Push your property to property portals, and automatically track leads, and applicants rental applications. Automatically create leases once an applicant has signed the lease agreement.
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6} md={6} sm={6} className="order-sm-2 order-1">
                        {/* <div className="duration duration-right rounded border p-2 px-4 position-relative shadow text-start">
                          2006
                        </div> */}
                      </Col>
                    </Row>
                  </div>

                  <div className="timeline-item mt-4">
                    <Row>
                      <Col lg={6} md={6} sm={6}>
                        {/* <div className="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                          2012
                        </div> */}
                      </Col>
                      <Col lg={6} md={6} sm={6}>
                        <Card className="event event-description-right rounded shadow border-0 overflow-hidden float-start">
                          <img src={todo} className="img-fluid" alt="" />
                          <CardBody>
                            <h5 className="mb-0 text-capitalize">Automate daily tasks</h5>
                            <p className="mt-3 mb-0 text-muted">
                              Send automated rent reminders, property reports. Respond to general inquiries using our AI chatbot.
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <div className="timeline-item mt-4">
                    <Row>
                      <Col lg={6} md={6} sm={6} className="order-sm-1 order-2">
                        <Card className="event event-description-left rounded shadow border-0 overflow-hidden float-start">
                          <img src={tasks} className="img-fluid" alt="" />
                          <CardBody>
                            <h5 className="mb-0 text-capitalize">Manage tenant requests and service providers</h5>
                            <p className="mt-3 mb-0 text-muted">
                              Receive maintenance requests from tenants. Create Jobs for service providers. Service providers can bid on jobs. Choose the best service provider for the job and assign the job to them.
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6} md={6} sm={6} className="order-sm-2 order-1">
                        {/* <div className="duration duration-right rounded border p-2 px-4 position-relative shadow text-start">
                          2013
                        </div> */}
                      </Col>
                    </Row>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
