import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

// Import images
// import homeImage from "../../assets/images/app/home.png";
import homeImage from "../../assets/images/app/zenstrin/1.png";

const Section = () => {  
    return (
      <React.Fragment>        
        <section className="bg-half-170 d-table w-100" id="home">
          <Container>
            <Row className="mt-5 align-items-center">
              <Col lg={6} md={7}>
                <div className="title-heading">
                  <h1 className="heading mb-3" style={{textAlign: 'center'}}>
                    Manage your leases with the{" "}
                    <span className="text" style={{color: "#f7961c"}}>Zenstrin</span> app
                  </h1>
                  <p className="para-desc text-muted" style={{textAlign: 'center'}}>
                    All your needs catered to from a single app.
                  </p>
                  <div className="mt-4" style={{display: 'flex', justifyContent: 'center'}}>
                    {/* <Link to="#" className="btn btn-primary mt-2 me-2">
                      <i className="uil uil-apple"></i> App Store
                    </Link>{" "}
                    <Link to="#" className="btn btn-outline-primary mt-2">
                      <i className="uil uil-google-play"></i> Play Store
                    </Link> */}
                    <Link to="//apps.apple.com/gb/app/zenstrin/id6459234020" target="_blank" rel="noopener noreferrer" className="btn btn-pills btn-primary mt-2 me-2" style={{display: 'flex', }}>
                      <i className="uil uil-apple" style={{fontSize:30, marginRight: 5}}></i>
                      <div>
                        <span className="d-block" style={{fontWeight: 'normal', fontSize: 14}}>Download on the</span>
                        <strong className="d-block " style={{fontWeight: 'medium', fontSize: 17}}>App Store</strong>
                      </div>
                    </Link>
                    <Link to="//play.google.com/store/apps/details?id=com.zenstrin.app" target="_blank" rel="noopener noreferrer" className="btn btn-pills btn-outline-primary mt-2 ms-1" style={{display: 'flex', }}>
                      <i className="uil uil-google-play" style={{fontSize:30, marginRight: 5}}></i>
                      <div>
                        <span className="d-block" style={{fontWeight: 'normal', fontSize: 14}}>Get it on</span>
                        <strong className="d-block " style={{fontWeight: 'medium', fontSize: 17}}>Google play</strong>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="text-md-end text-center ms-lg-4">
                  <img src={homeImage} className="img-fluid" alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );  
}

export default Section;
