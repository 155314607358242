import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Button,
  Input,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Images
import bgimg from "../../../../assets/images/1.jpg";
import Newsletter from "../../../../components/Layout/Newsletter";
import DownloadResource from "../../../../components/Layout/DownloadResource";

export default class WhyLettingAgenciesShouldEmbraceSpreadsheetsForPropertyManagement extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu").classList.add("nav-light");
    })
    window.addEventListener("scroll", this.scrollNavigation, true);
  }
  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      // document.querySelector(".shoppingbtn").classList.remove("btn-light");
      // document.querySelector(".settingbtn").classList.remove("btn-light");
      // document.querySelector(".shoppingbtn").classList.add("btn-primary");
      // document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      // document.querySelector(".shoppingbtn").classList.remove("btn-primary");
      // document.querySelector(".settingbtn").classList.remove("btn-soft-primary");
      // document.querySelector(".shoppingbtn").classList.add("btn-light");
      // document.querySelector(".settingbtn").classList.add("btn-light");
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="bg-half-170 d-table w-100" style={{ background: `url(${bgimg}) center center` }}>
          <div className="bg-overlay"></div>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h3 className="title text-white title-dark mb-0">
                    Why Letting Agencies Should Embrace <br />Spreadsheets for Property Management </h3>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="#">Blog</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">Why Letting Agencies Should Embrace Spreadsheets for Property Management</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10}>
                <Row>
                  <Col md={2} className="d-none d-md-block">
                    <ul className="list-unstyled text-center sticky-bar social-icon social mb-0">
                      <li className="mb-2 h6">Share</li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.instagram.com/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="#" className="rounded mb-1">
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li> */}
                      <li>
                        <Link to="//www.linkedin.com/company/zenstrin/" target="_blank" rel="noopener noreferrer" className="rounded mb-1">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </Col>

                  <div className="col-md-10">

                    <ul className="list-unstyled d-flex justify-content-between mt-4">
                      <li className="list-inline-item user me-2">
                        <Link to="#" className="text-muted">
                          <i className="uil uil-user text-dark"></i> Zenstrin
                        </Link>
                      </li>
                      <li className="list-inline-item date text-muted">
                        <i className="uil uil-calendar-alt text-dark"></i>{" "}
                        1st April, 2024
                      </li>
                    </ul>

                    <img
                      src={"https://firebasestorage.googleapis.com/v0/b/zenstrin.appspot.com/o/zenstrin_images%2Fblogs%2Fwhy-letting-agencies-should-embrace-spreadsheets-for-property-management.jpg?alt=media&token=d4c33bce-b1a4-49ec-970d-f8f735a83b69"}
                      className="img-fluid rounded-md shadow"
                      alt=""
                    />

                    <h5 className="mt-4">
                      Why Letting Agencies Should Embrace Spreadsheets for Property Management
                    </h5>

                    <p className="text-muted">
                      In the dynamic world of property management, staying organized and efficient is key to success. Whether you're managing a handful of properties or overseeing a vast portfolio, keeping track of income and expenses is paramount. While there are various software solutions available, many letting agencies overlook one of the most powerful tools at their disposal: spreadsheets. In this blog, we'll explore why letting agencies should leverage spreadsheets to track property income and expenses, and how doing so can streamline operations and boost profitability.
                    </p>

                    <h5 className="mt-4">1. Flexibility and Customization</h5>

                    <p className="text-muted">
                      Spreadsheets offer unparalleled flexibility and customization options. Unlike pre-packaged software, which may have limitations in terms of features or data presentation, spreadsheets allow letting agencies to tailor their tracking system to their specific needs. Whether it's creating custom formulas, designing intuitive layouts, or incorporating macros for automation, the possibilities are virtually endless.

                      With spreadsheets, letting agencies can adapt their tracking methods as their business evolves. They can easily add new columns, adjust formulas, or create new sheets to accommodate changes in their property portfolio or accounting practices. This level of flexibility ensures that the tracking system remains relevant and efficient over time.
                    </p>

                    <h5 className="mt-4">2. Cost-Effectiveness</h5>

                    <p className="text-muted">
                      One of the most compelling reasons for letting agencies to use spreadsheets is their cost-effectiveness. While dedicated property management software often comes with subscription fees or licensing costs, spreadsheets are typically included in standard office software packages or available for free. This makes them an attractive option for agencies looking to minimize expenses without sacrificing functionality.

                      Furthermore, spreadsheets require minimal training to use effectively. Most employees are already familiar with basic spreadsheet functions, reducing the need for extensive training or onboarding. This simplicity not only saves time but also ensures that employees can start using the tracking system immediately, without the need for specialized knowledge or skills.
                    </p>

                    <h5 className="mt-4">3. Comprehensive Data Analysis</h5>

                    <p className="text-muted">
                      Spreadsheets excel at data analysis, allowing letting agencies to gain valuable insights into their property income and expenses. By organizing data in a structured manner, agencies can easily calculate key performance metrics such as occupancy rates, rental yields, and return on investment. They can also identify trends and patterns over time, enabling them to make informed decisions about pricing, marketing, and property management strategies.

                      With advanced features such as pivot tables, charts, and conditional formatting, letting agencies can visualize their data in meaningful ways. Whether it's comparing rental income across different properties, tracking maintenance expenses by category, or analyzing seasonal variations in occupancy rates, spreadsheets make it easy to extract actionable insights from raw data.
                    </p>

                    <h5 className="mt-4">4. Streamlined Collaboration</h5>

                    <p className="text-muted">
                      Collaboration is essential in the property management industry, where multiple stakeholders may be involved in the management of a single property. Spreadsheets facilitate collaboration by allowing multiple users to access and edit the same document simultaneously. This real-time collaboration ensures that everyone has access to the latest information and can contribute to the tracking process as needed.

                      Additionally, spreadsheets offer robust sharing and permissions features, allowing letting agencies to control who can view, edit, or comment on specific parts of the document. This ensures data security and prevents unauthorized access or modifications. With built-in revision history and version control, agencies can also track changes and revert to previous versions if necessary, providing peace of mind in the event of errors or discrepancies.
                    </p>

                    <h5 className="mt-4">5. Integration with Other Tools</h5>

                    <p className="text-muted">
                      While spreadsheets offer comprehensive functionality on their own, they can also be integrated with other tools and software systems used by letting agencies. Whether it's accounting software, property management platforms, or CRM systems, spreadsheets can serve as a central hub for consolidating data from multiple sources.

                      By integrating spreadsheets with other tools, letting agencies can streamline their workflows and eliminate duplicate data entry. They can also leverage the strengths of each tool, using spreadsheets for data analysis and reporting while relying on specialized software for specific tasks such as invoicing, tenant communication, or maintenance tracking.
                    </p>

                    <h5 className="mt-4">Conclusion</h5>

                    <p className="text-muted">
                      In conclusion, letting agencies stand to benefit greatly from using spreadsheets to track property income and expenses. Spreadsheets offer unmatched flexibility, allowing agencies to customize their tracking system to their specific needs. They are also cost-effective, easy to use, and support comprehensive data analysis and collaboration.

                      By leveraging spreadsheets, letting agencies can streamline their operations, gain valuable insights into their property portfolio, and make informed decisions that drive profitability and growth. In an industry where efficiency and accuracy are paramount, embracing spreadsheets is not just a choice but a strategic imperative.
                    </p>

                    <DownloadResource resource={{
                      name: "Property Income and Expense Spreadsheet",
                      url: "https://docs.google.com/spreadsheets/d/1KP0UwjRdJ1p97WdU9V-y9lis3YwJArt56DoXb7uyAAE/copy",
                    }} 
                    />

                    <Newsletter />
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
